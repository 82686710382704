import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import backGroundPrivacy from "../../assets/images/background_gradients/privacy_bg_top.png";

const styles = makeStyles((theme: SinesTheme) => ({
  privacyBackground: {
    background: `url(${backGroundPrivacy})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#0C0C0C",
  },
  title: {
    textAlign: "center",
    fontSize: 66,
    fontFamily: "Montserrat SemiBold",
    marginBottom: 40,
  },
  icons: {
    display: "flex",
    justifyContent: "center",
    columnGap: 40,
    marginBottom: 80,
  },
  subtitle: {
    fontSize: 42,
    textAlign: "center",
    fontFamily: "Montserrat",
    marginBottom: 40,
  },
}));

export default styles;
