import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  title: {
    fontSize: 20,
    textAlign: "left",
    marginTop: 40,
    marginBottom: 20,
    fontFamily: "Montserrat SemiBold",
  },
  subtitle: {
    fontSize: 16,
    textAlign: "left",
    marginBottom: 10,
    fontFamily: "Montserrat SemiBold",
  },
  text: {
    fontSize: 16,
    textAlign: "left",
    fontFamily: "Montserrat",
  },
}));

export default styles;
