import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  pricingBoard: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  boardSection: {
    marginBottom: 20,
  },
  boardTitle: {
    background: "red",
    borderRadius: 6,
    backgroundImage:
      "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, #914e66 100%)",
    padding: "3px 20px",
    marginBottom: 10,
    "& p": {
      color: "#FFFFFF",
      fontSize: 17,
      fontFamily: "Montserrat Semibold",
    },
  },
  planItems: {
    background: "#0C0C0C",
    padding: "10px 14px 10px 14px",
    color: "#FFF",
    borderRadius: 26,
    boxShadow: "2px 1000px 1px #242424 inset",
    backgroundImage:
      "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
    backgroundOrigin: "border-box",
    border: "1px solid transparent",
    "& p.withInfo": {
      height: 90,
    },
    "& p": {
      fontSize: 12,
      // paddingTop: 15,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: 62,
      "& span": {
        fontSize: 12,
        display: "block",
        marginBottom: 10,
      },
      "& span:last-child": {
        fontSize: 9,
      },
    },
  },
  planCheckbox: {
    padding: "10px 10px 10px 10px",
  },
  icons: {
    width: 18,
    height: 18,
  },
  checkBoxRow: {
    display: "flex",
    borderBottom: "0.5px solid",
    height: 62,
    "&.withInfo": {
      height: 90,
    },
  },
  checkboxItem: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default styles;
