import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const styles = makeStyles((theme: SinesTheme) => ({
  pageHero: {
    marginBottom: 80,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  title: {
    fontSize: 42,
    lineHeight: "50px",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    lineHeight: "21px",
  },
  heroImageContainer: {
    display: "flex",
    justifyContent: "right",
    position: "relative",
    height: "100%",
    "&:before": {
      content: "''",
      backgroundImage:
        "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
      filter: "blur(30px)",
      top: "-1%",
      bottom: "-1%",
      left: "9%",
      right: "-1%",
      borderRadius: 26,
      position: "absolute",
    },
  },
  heroImage: {
    width: "90%",
    maxHeight: "100%",
    borderRadius: 26,
    zIndex: 1,
    objectFit: "cover",
  },
  action: {
    marginTop: 30,
  },
  footer: {
    marginTop: 8,
  },
  [theme.breakpoints.down("sm")]: {
    action: {
      marginTop: 80,
    },
  },
}));

export default styles;
