import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import api from "../../api/api";
import { ReactComponent as GeneralComunication } from "../../assets/images/contact_us/card_general.svg";
import { ReactComponent as Partnership } from "../../assets/images/contact_us/card_partnership.svg";
import { ReactComponent as Tech } from "../../assets/images/contact_us/card_tech.svg";
import FacebookIcon from "../../assets/images/icons/social_failbook.svg";
import InstagramIcon from "../../assets/images/icons/social_instagram.svg";
import TwitterIcon from "../../assets/images/icons/social_twitter.svg";
import CustomButton from "../../components/CustomButton";
import { Input } from "../../components/Forms/Input";
import JoinUsFooter from "../../components/JoinUsFooter";
import Loading from "../../components/Loading";
import PageWrapper from "../../components/PageWrapper";
import { useStyles } from "./styles";
import { defaultValues, schema1, schema2 } from "./yupSchema";

export interface IUserBusiness {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  companyWebsite: string;
  body: string;
  topic?: string;
}
const faqItems = [
  { name: "General", section: 0 },
  { name: "Sales", section: 1 },
  { name: "Support", section: 2 },
];

enum EmailStatus {
  Initial = 0,
  Sending,
  Success,
  Error,
}

function ContactUs() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const [section, setSection] = useState(0);
  const [schemaSelected, setSchemaSelected] = useState(schema1);
  const [emailStatus, setEmailStatus] = useState(EmailStatus.Initial);
  const [selected, setSelected] = useState(faqItems[0].name);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schemaSelected),
    shouldUnregister: false,
    defaultValues,
  });

  useEffect(() => {
    switch (searchParams.get("topic")) {
      case "":
        setSection(0);
        break;
      case "sales":
        setSection(1);
        break;
      case "support":
        setSection(2);
        break;

      default:
        setSection(0);
        break;
    }
  }, [searchParams]);

  useEffect(() => {
    setSelected(faqItems[section].name);
    setSchemaSelected(section === 1 ? schema2 : schema1);
  }, [section]);

  const formSubmitHandler: SubmitHandler<IUserBusiness> = async (payload) => {
    if (section !== 1) {
      payload.companyName = "";
      payload.companyWebsite = "";
    }
    payload.topic = selected;
    try {
      setEmailStatus(EmailStatus.Sending);
      await api.post("contact", payload);
      setEmailStatus(EmailStatus.Success);
    } catch (error) {
      setEmailStatus(EmailStatus.Error);
    }
  };

  return (
    <>
      <PageWrapper>
        <Container>
          <Grid container spacing={4} columnSpacing={{ md: 10, lg: 20 }}>
            <Grid item xs={12} md={6}>
              <Typography className={classes.pageTitle}>
                We'd love to hear from you!
              </Typography>
              <Box className={classes.socialMediaIcons}>
                <Link href="https://www.facebook.com/sinesglobal/">
                  <img
                    className={classes.socialMediaIcon}
                    src={FacebookIcon}
                    alt="facebook"
                  />
                </Link>
                <Link href="https://twitter.com/sines_app">
                  <img
                    className={classes.socialMediaIcon}
                    src={TwitterIcon}
                    alt="twitter"
                  />
                </Link>
                <Link href="https://www.instagram.com/sines.app/">
                  <img
                    className={classes.socialMediaIcon}
                    src={InstagramIcon}
                    alt="instagram"
                  />
                </Link>
              </Box>
              <Box
                className={[classes.infoBox, classes.gradientBorder].join(" ")}
              >
                <Box className={classes.infoBoxImage}>
                  <GeneralComunication />
                </Box>
                <Box>
                  <Typography className={classes.infoBoxTitle}>
                    General communications
                  </Typography>
                  <Typography className={classes.infoBoxText}>
                    Say hello, give us feedback, suggestions or request
                    features.
                  </Typography>
                </Box>
              </Box>
              <Box
                className={[classes.infoBox, classes.gradientBorder].join(" ")}
              >
                <Box className={classes.infoBoxImage}>
                  <Partnership />
                </Box>
                <Box>
                  <Typography className={classes.infoBoxTitle}>
                    Partnership opportunities
                  </Typography>
                  <Typography className={classes.infoBoxText}>
                    Let us know about collaborative opportunities + questions
                    you or your company may have.
                  </Typography>
                </Box>
              </Box>
              <Box
                className={[classes.infoBox, classes.gradientBorder].join(" ")}
              >
                <Box className={classes.infoBoxImage}>
                  <Tech />
                </Box>
                <Box>
                  <Typography className={classes.infoBoxTitle}>
                    Technical or account support
                  </Typography>
                  <Typography className={classes.infoBoxText}>
                    If you have technical or account issues, please reach out.
                    We're here to help!
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.formSelectors}>
                {faqItems.map((element) => {
                  return (
                    <Button
                      className={[
                        classes.formSelectorsButton,
                        selected === element.name ? "active" : "",
                      ].join(" ")}
                      key={element.name}
                      onClick={() => {
                        if (element.section !== 0) {
                          setSearchParams(
                            `topic=${element.name.toLowerCase()}`
                          );
                        } else {
                          setSearchParams("");
                        }
                      }}
                    >
                      {element.name}
                    </Button>
                  );
                })}
              </Box>
              {emailStatus === EmailStatus.Success ? (
                <Box
                  className={[classes.formBox, classes.gradientBorder].join(
                    " "
                  )}
                >
                  <Box>
                    <Typography className={classes.emailSuccessTitle}>
                      Your message has been sent!
                    </Typography>
                    <Typography className={classes.emailSuccessSubtitle}>
                      Thank you for contacting us.
                      <br />
                      Our team will reply as soon as possible.
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <form
                  id="business-information-form"
                  onSubmit={handleSubmit(formSubmitHandler)}
                  key="businessInformation"
                >
                  <Box
                    className={[classes.formBox, classes.gradientBorder].join(
                      " "
                    )}
                  >
                    <Grid container columnSpacing={4} rowSpacing={1}>
                      <Grid item xs={12} md={6}>
                        <Input
                          required
                          name="firstName"
                          placeholder="First name"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          required
                          name="lastName"
                          placeholder="Last name"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          name="email"
                          placeholder="Email"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      {section === 1 && (
                        <>
                          <Grid item xs={12}>
                            <Input
                              name="companyName"
                              placeholder="Company name"
                              control={control}
                              errors={errors}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Input
                              name="companyWebsite"
                              placeholder="Company website"
                              control={control}
                              errors={errors}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <Input
                          name="body"
                          placeholder="How can we help?"
                          control={control}
                          errors={errors}
                          multiline
                          minRows={4}
                        />
                      </Grid>
                    </Grid>
                    <Box className={classes.saveChangesButton}>
                      {emailStatus === EmailStatus.Error && (
                        <Typography className={classes.emailError}>
                          An error occurred sending your message.
                          <br />
                          Please try again.
                        </Typography>
                      )}
                      <CustomButton
                        label="Submit"
                        type="submit"
                        fullWidth
                        disabled={emailStatus === EmailStatus.Sending}
                      />
                    </Box>
                  </Box>
                </form>
              )}
            </Grid>
          </Grid>
        </Container>
      </PageWrapper>
      <JoinUsFooter finalText="If you need a program that's custom to your business, our team can help" />
      {emailStatus === EmailStatus.Sending && <Loading />}
    </>
  );
}
export default ContactUs;
