import { Typography } from "@mui/material";
import React from "react";
import styles from "./styles";

type Props = {
  element: {
    label: string;
    auxLabel?: string;
    text: string;
    topics?: string[] | null;
  };
};

const TextComponent: React.FC<Props> = ({ element }) => {
  const classes = styles();
  return (
    <>
      {element.label && (
        <Typography className={classes.title}>{element.label}</Typography>
      )}
      {element.auxLabel && (
        <Typography className={classes.subtitle}>{element.auxLabel}</Typography>
      )}
      {element.text && (
        <Typography className={classes.text}>{element.text}</Typography>
      )}
      {element.topics &&
        element.topics.map((information) => {
          return (
            <Typography key={information} className={classes.text}>
              &bull; {information}
            </Typography>
          );
        })}
    </>
  );
};
export default TextComponent;
