import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  clustersContainer: {
    marginTop: 60,
  },
  imageCluster: {
    height: "calc(100% - 2px)",
    width: "calc(100% - 2px)",

    filter: "drop-shadow(0 0px 20px black)",
    borderRadius: 26,
    objectFit: "cover",
  },
  imageClusterContainer: {
    maxWidth: 240,
    position: "relative",
    height: "100%",
    textAlign: "center",
    "&:before": {
      content: "''",
      backgroundImage:
        "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
      filter: "blur(15px)",
      top: -5,
      bottom: -5,
      left: -5,
      right: -5,
      borderRadius: 26,
      position: "absolute",
    },
  },
  alignLeft: {
    display: "flex",
    justifyContent: "flex-end",
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-start",
  },
  [theme.breakpoints.down("lg")]: {
    imageClusterContainer: {
      margin: "0 20px",
    },
  },
}));

export default styles;
