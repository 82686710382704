import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const FooterStyles = makeStyles((theme: SinesTheme) => ({
  footerGrid: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    paddingTop: "40px",
    backgroundColor: "#121212",
    zIndex: 6,
  },
  sinesLogo: {
    width: "129px",
    height: "88px",
  },
  logoSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  logoSubtitle: {
    color: " #FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    width: "119px",
  },
  menu: {
    display: "flex",
    // alignItems: "end",
    // paddingBottom: "28px",
    justifyContent: "center",
  },
  menu2: {
    display: "flex",
    // alignItems: "end",
    paddingBottom: "58px",
    justifyContent: "center",
  },
  menuButton: {
    fontFamily: "Montserrat Medium",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "left",
    paddingBottom: "10px",
    cursor: "pointer",
  },
  socialMediaTitle: {
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "left",
    paddingBottom: "12px",
  },
  socialMediaIcon: {
    width: "26px",
    height: "26px",
  },
  socialMediaIconsGrid: {
    display: "flex",
    width: "118px",
    justifyContent: "space-between",
    paddingTop: "20px",
    paddingBottom: "20px",
    marginRight: "5px",
  },
  logoWrapper: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  qrCodeWrapper: {
    paddingTop: "10px",
    display: "flex",
    alignItems: "end",
  },
  appStore: {
    width: "90px",
    height: "30px",
    marginLeft: "10px",
    cursor: "pointer",
  },
  poweredWrapper: {
    paddingTop: "8px",
  },
  poweredTypo: {
    fontSize: "10px",
    lineHeight: "12px",
    textAlign: "left",
  },
  nearTypo: {
    color: "#B66481",
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "left",
  },
  qrGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingBottom: "28px",
  },
  menuXS: {
    display: "none",
  },
  //TABLET VIEW -LANDSCAPE
  [theme.breakpoints.down("md")]: {
    logoSection: {
      alignItems: "center",
    },
  },
  [theme.breakpoints.down("sm")]: {
    footerGrid: { paddingTop: "20px" },
    logoSection: {
      paddingBottom: 10,
    },
    qrGrid: {
      alignItems: "center",
    },
    menu2: {
      display: "none",
    },
    menuButton: { fontSize: "12px" },
    socialGrid: {
      display: "flex",
      justifyContent: "center",
    },
    menu: {
      display: "none",
    },
    menuXS: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: 15,
    },
    socialMediaIconsGrid: {
      marginRight: 0,
    },
  },
}));

export { FooterStyles };
