export const textStyled = (text: string) =>
  text
    .split(" ")
    .map((word) =>
      word.startsWith("$") ? (
        <span style={{ color: "#B66481", fontFamily: "Montserrat SemiBold" }}>
          {word.replace("$", "")}{" "}
        </span>
      ) : (
        <>{word} </>
      )
    );
