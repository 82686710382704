import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  customButton: {
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: "Montserrat SemiBold",
    textAlign: "left",
    alignSelf: "center",
    textTransform: "none",
    borderRadius: "26px",
    border: "solid 1px transparent",
    backgroundOrigin: "border-box",
    transition: "none",
    padding: "0px 0px",
    // "&:hover": { background: "#242424" },
  },
  backgroundColored: {
    backgroundImage: "linear-gradient(to right, #775DA8, #F06B5D)",
  },
  outline: {
    boxShadow: "0px 1000px 0px #242424 inset",
    backgroundImage:
      "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
    backgroundOrigin: "border-box",
    padding: "12px 40px",
  },
  buttonPadding: {
    padding: "8px 32px",
  },
  chevron: {
    marginLeft: 10,
  },
}));

export default styles;
