import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  optimizeContainer: {
    // textAlign: "center",
  },
  image: {
    maxWidth: 384,
    width: "100%",
    filter: "drop-shadow(0 0px 20px black)",
  },
  phones: {},
  clusters: {
    marginTop: 60,
  },
  imageCluster: {
    maxWidth: 240,
    width: "100%",
    filter: "drop-shadow(0 0px 20px black)",
    borderRadius: 26,
    objectFit: "cover",
    height: "100%",
  },
  imageClusterContainer: {
    position: "relative",
    height: "100%",
    "&:before": {
      content: "''",
      backgroundImage:
        "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
      filter: "blur(15px)",
      top: -5,
      bottom: -5,
      left: -5,
      right: -5,
      borderRadius: 26,
      position: "absolute",
    },
  },
}));

export default styles;
