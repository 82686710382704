import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const brainWavesStyle = makeStyles((theme: SinesTheme) => ({
  brainWavesContainer: {
    // border: "1px solid #fff",
    borderRadius: 26,
    padding: "20px 60px",
  },
  wrapper: {
    display: "flex",
    marginBottom: 10,
  },
  textWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    flex: "50%",
    padding: "0 40px",
  },
  title: {
    fontSize: "42px",
    lineHeight: "50px",
    textAlign: "left",
  },
  imgWrapper: {
    flex: "50%",
    textAlign: "right",
  },
  imgWrapperSecond: {
    textAlign: "left",
  },
  // buttonWrapper: {
  //   marginTop: 40,
  // },
  image: {
    width: "80%",
    maxWidth: 340,
    maxHeight: 340,
  },
  [theme.breakpoints.down("md")]: {
    title: {
      fontSize: "30px",
      textAlign: "center",
    },
    wrapper: {
      flexDirection: "column",
    },
    textWrapper: {
      flex: "100%",
      padding: 0,
    },
    imgWrapper: {
      alignSelf: "center",
      textAlign: "center",
    },
    // buttonWrapper: {
    //   textAlign: "center",
    //   marginTop: 20,
    //   marginBottom: 20,
    // },
    image: {
      maxHeight: 270,
    },
  },
  [theme.breakpoints.down("sm")]: {
    title: {
      fontSize: "25px",
    },
    image: {
      maxHeight: 200,
    },
  },
}));

export { brainWavesStyle };
