import TagManager from "react-gtm-module";
import Footer from "./components/Footer";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import SinesRoutes from "./routes";
import { envConfig } from "./config/config";

TagManager.initialize({
  gtmId: envConfig.REACT_APP_GTM_ID,
  preview: envConfig.REACT_APP_GTM_PREVIEW || undefined,
  auth: envConfig.REACT_APP_GTM_AUTH || undefined,
});

function App() {
  return (
    <>
      <ResponsiveAppBar />
      <SinesRoutes />
      <Footer />
    </>
  );
}

export default App;
