import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import backGroundWellness from "../../assets/images/background_gradients/footer_trimmed.png";
const styles = makeStyles((theme: SinesTheme) => ({
  title: {
    fontSize: "42px",
    textAlign: "center",
    marginBottom: 60,
  },
  smallText: {
    textAlign: "center",
  },
  mediumText: {
    fontSize: "24px",
    textAlign: "center",
    marginTop: "40px",
  },
  wrapperBg: {
    backgroundImage: `url(${backGroundWellness})` /* The image used */,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#0C0C0C",
    backgroundSize: "100% 200%",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "120px",
    marginInline: "40px",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    buttonWrapper: {
      marginInline: "10px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    mediumText: {
      fontSize: "20px",
    },
    title: {
      fontSize: "25px",
    },
    buttonsWrapper: {
      flexDirection: "column",
    },
    buttonWrapper: {
      margin: "10px",
    },
  },
}));

export default styles;
