import * as yup from "yup";
const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;

const regex = /^(?!\s+$).*/gm;

export const schema1 = yup.object().shape({
  firstName: yup
    .string()
    .required("Field required!")
    .matches(regex, "Field required!"),
  lastName: yup
    .string()
    .required("Field required!")
    .matches(regex, "Field required!"),
  email: yup.string().email("Invalid email").required("Field required!"),
  companyName: yup.string(),
  companyWebsite: yup.string(),
  body: yup
    .string()
    .required("Field required!")
    .matches(regex, "Field required!"),
});

export const schema2 = yup.object().shape({
  firstName: yup
    .string()
    .required("Field required!")
    .matches(regex, "Field required!"),
  lastName: yup
    .string()
    .required("Field required!")
    .matches(regex, "Field required!"),
  email: yup.string().email("Enter a valid email").required("Field required!"),
  companyName: yup
    .string()
    .required("Field required!")
    .matches(regex, "Field required!"),
  companyWebsite: yup
    .string()
    .matches(URL, "Enter a valid url")
    .required("Field required!"),
  body: yup
    .string()
    .required("Field required!")
    .matches(regex, "Field required!"),
});

export const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  companyName: "",
  companyWebsite: "",
  body: "",
};
