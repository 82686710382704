import { Box, Container, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import mobileHeaderImage from "../../../assets/images/individualsImg/mobileHeaderImage.png";
import CustomButton from "../../../components/CustomButton";
import { TextandImageStyle } from "./styles";

type Props = {
  children?: React.ReactNode;
  container?: boolean;
};

const IntroPage: React.FC<Props> = ({ children, container }) => {
  const classes = TextandImageStyle();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const mainSubtitle = isMobile ? (
    <>
      SINES is a union of music and technology that elevates mental health,
      wellness and performance optimization in all sectors of industry and
      society.
    </>
  ) : (
    <>
      SINES is a union of music and technology that <br /> elevates mental
      health, wellness and performance <br /> optimization in all sectors of
      industry and society.
    </>
  );

  return (
    <Container maxWidth="lg">
      <Grid
        container
        className={classes.intro}
        justifyContent={{ sm: "center", lg: "flex-start" }}
      >
        <Grid item xs={12} md={7} className={classes.textWrapper}>
          <Typography className={classes.mainTitle}>
            Empowering <br /> people + businesses <br /> to achieve wellbeing
          </Typography>
          <Typography className={classes.mainSubTitle}>
            {mainSubtitle}
          </Typography>
          <Box sx={{ marginTop: 10, marginBottom: 1, display: "flex" }}>
            <CustomButton label="Sign up" url="/pricing" />
          </Box>
          <Typography>
            Start your 7-day free trial. No payment details required.
          </Typography>
        </Grid>
        {mdUp && (
          <Grid item sm={12} md={5}>
            <Box className={classes.heroImage}>
              <img
                className={classes.mobileHomePageImg}
                src={mobileHeaderImage}
                alt="Intro"
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default IntroPage;
