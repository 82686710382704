import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const midSectionStyle = makeStyles((theme: SinesTheme) => ({
  root: {
    padding: "80px 0",
    // backgroundImage: `url(${backGroundMid})` /* The image used */,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // backgroundColor: "#000000",
    backgroundPosition: "center",
  },
  buttonBoardGrid: {},
  buttonBoard: {
    color: "#FFFFFF",
    fontSize: "18px",
    fontFamily: "Montserrat Semibold",
    lineHeight: "22px",
    textAlign: "left",
    alignSelf: "center",
    textTransform: "none",
    "&:hover": { background: "#242424" },
    borderRadius: "26px",
    padding: "10px 30px",
    border: "solid 1px transparent",
    "&.active": {
      boxShadow: "2px 1000px 1px #242424 inset",
      backgroundImage:
        "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
      backgroundOrigin: "border-box",
    },
    transition: "none",
  },
  midBoard: {
    backgroundColor: "rgba(36, 36, 36, 0.6)",
    border: "1px solid rgba(201, 102, 117, 0.5)",
    borderRadius: "26px",
    minHeight: "544px",
  },
  btnGrid: {
    display: "flex",
    paddingTop: "20px",
    justifyContent: "space-around",
  },
  boards: {
    padding: "40px 80px",
  },
  [theme.breakpoints.down("lg")]: {
    midBoard: {
      minHeight: "446px",
    },
  },
  [theme.breakpoints.down("md")]: {
    buttonBoard: {
      fontSize: "14px",
      padding: "8px 20px",
    },
    midBoard: {
      minHeight: "298px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    btnGrid: {
      paddingLeft: 20,
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
    buttonBoardGrid: {
      flex: 1,
    },
    buttonBoard: {
      marginBottom: 20,
    },
    boards: {
      padding: "0px 40px 40px 40px",
    },
  },
}));

export { midSectionStyle };
