import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const gradient =
  "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)";

/**
 *
 * .classNAme {}
 * .classNAme::after {}
 * .classNAme::before {}
 * .classNAme:hover {}
 */
const modalStyle = makeStyles((theme: SinesTheme) => ({
  modalStyle: {
    "& .MuiDialog-paper": {
      borderRadius: "30px",
      height: "470px",
      width: "660px",
      overflow: "hidden",
      background: gradient,

      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },

      "& >div": {
        position: "relative",
        padding: "20px 45px",
        height: "calc(100% - 2px)",
        width: "calc(100% - 2px)",
        background: "#2D2D2D",
        borderRadius: "30px",
        margin: "1px",
      },
      "&::before": {
        content: '""',
        position: "fixed",
        height: "470px",
        width: "600px",
        borderRadius: "30px",
        filter: "blur(9px)",
        background: gradient,
      },
    },

    "& .MuiDialog-root": {
      background: "red",
      height: "900px",
      width: "900px",
    },
  },
  titleGrid: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  modalTitle: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "38px",
    lineHeight: "46px",
    textAlign: "center",
  },
  sliderGrid: {
    height: "52px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0",
  },
  slider: {
    color: "rgba(255, 255, 255, 0.9)",
    borderRadius: "1px",
    height: "2px",
    "& .MuiSlider-thumb": {
      color: "#2D2D2D",
      border: "1px solid #7A5EA7",
      borderRadius: "100%",
      width: "32px",
      height: "32px",
    },

    "& .MuiSlider-thumb:active": {
      boxShadow:
        "radial-gradient(circle, rgba(122,94,167,1) 0%, rgba(161,98,142,1) 50%, rgba(201,102,117,1) 100%)",
    },
  },
  sliderWarning: {
    color: "rgba(255, 255, 255, 0.9)",
    borderRadius: "1px",
    height: "2px",
    "& .MuiSlider-thumb": {
      color: "#2D2D2D",
      border: "1px solid #7A5EA7",
      borderRadius: "100%",
      width: "32px",
      height: "32px",
    },

    "& .MuiSlider-thumb:active": {
      boxShadow:
        "radial-gradient(circle, rgba(122,94,167,1) 0%, rgba(161,98,142,1) 50%, rgba(201,102,117,1) 100%)",
    },
    "& .MuiSlider-track": {
      color: "#B66481",
      height: "2px",
    },
  },
  sliderCounter: {
    background:
      "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
    borderRadius: "100%",
    width: "55px",
    height: "47px",
    marginLeft: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sliderScale: {
    display: "flex",
    textAlign: "center",
    backgroundColor: "#2D2D2D",
    width: "96%",
    height: "94%",
    borderRadius: "100%",
    alignContent: "center",
    justifyContent: "center",
  },
  priceTitle: {
    color: "#FFFFFF",
    fontFamily: "Montserrat Semibold",
    fontSize: "14px",
    lineHeight: "21px",
  },
  priceSubtitle: {
    color: "#C4C4C4",
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "21px",
  },
  priceGrid: {
    display: "flex",
    paddingBottom: "20px",
  },
  totalPriceGrid: {
    backgroundColor: "#363636",
    borderRadius: "21px",
    width: "100%",
    height: "59px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  totalPriceText: {
    color: "#FFFFFF",
    fontFamily: "Montserrat Semibold",
    fontSize: "18px",
    lineHeight: "21px",
    textAlign: "left",
  },
  cardBtnGrid: {
    justifyContent: "center",
    display: "flex",
    marginTop: "47px",
  },
  cardButton: {
    backgroundImage: "linear-gradient(68.02deg, #775DA8 0%, #F06B5D 100%)",
    borderRadius: "26px",
    width: "296px",
    height: "52px",
    color: "#FFFFFF",
    fontFamily: "Montserrat Semibold",
    fontSize: "17px",
    lineHeight: "20px",
    textAlign: "center",
    textTransform: "none",
  },
  btnSubtitleGrid: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
  },
  buttonSubtitle: {
    color: "#FFFFFF",
    fontFamily: "Montserrat Medium",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
  },
  totalPriceGridWarning: {
    backgroundColor: "#363636",
    borderRadius: "21px",
    height: "130px",
    padding: "0 10px",
  },
  titleTeamsGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  totalPriceTitle: {
    color: "#FFFFFF",
    fontFamily: "Montserrat Semibold",
    fontSize: "18px",
    lineHeight: "21px",
    textAlign: "left",
  },
  teamsText: {
    color: "#FFFFFF",
    fontFamily: "Montserrat Medium",
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
  },
  teamsTextGrid: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  sliderNumber: {
    alignSelf: "center",
    textAlign: "center",
    color: "#FFFFFF",
    fontFamily: "Montserrat Medium",
    fontSize: "18px",
    lineHeight: "22px",
  },
  totalPriceContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    modalTitle: {
      fontSize: "20px",
      lineHeight: "35px",
    },
    totalPriceGridWarning: {
      height: "140px",
    },
    totalPriceTitle: {
      fontSize: "16px",
    },
    teamsText: {
      fontSize: "14px",
    },
    modalStyle: {
      "& .MuiDialog-paper": {
        borderRadius: "30px",
        height: "450px",
        width: "450px",
        overflow: "hidden",
        background: gradient,

        "& >div": {
          height: "448px",
          width: "448px",
        },
        "&::before": {
          height: "450px",
          width: "450px",
        },
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    modalStyle: {
      "& .MuiDialog-paper": {
        borderRadius: "30px",
        height: "500px",
        width: "300px",
        overflow: "hidden",
        background: gradient,

        "& >div": {
          height: "498px",
          width: "298px",
          padding: "5px ",
          paddingLeft: "25px",
          paddingRight: "15px",
        },
        "&::before": {
          height: "500px",
          width: "300px",
        },
      },
    },
    cardBtnGrid: {
      marginTop: "65px",
    },
  },
}));

export { modalStyle };
