import { Box, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TabletImg from "../../../assets/images/tablet_.png";
import { cardSectionStyle } from "./styles";

function CardSection() {
  const classes = cardSectionStyle();
  const theme = useTheme();
  const xsUp = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Box sx={{ padding: "80px 0" }} className={classes.supportContainer}>
      <Container maxWidth="lg">
        <Typography className={classes.title}>
          Support for your&nbsp;
          {!xsUp && <br />}
          entire day
        </Typography>
        <img
          src={TabletImg}
          alt="Sines App"
          className={classes.image}
          loading="lazy"
        />
      </Container>
    </Box>
  );
}
export default CardSection;
