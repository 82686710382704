import { Box, Grid, Typography } from "@mui/material";
import PlusSign from "../../../components/PlusSign";
import styles from "./styles";

type Props = {
  info: Array<string>;
};
const Elevate: React.FC<Props> = ({ info }) => {
  const classes = styles();

  return (
    <Box className={classes.elevate}>
      <Grid container spacing={2}>
        {info.map((str) => {
          return (
            <Grid item xs={12} sm={6} md={6} key={str}>
              <Box className={classes.textPlusContainer}>
                <PlusSign />
                <Typography>{str}</Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Elevate;
