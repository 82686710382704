import axios, { AxiosError } from "axios";
import { envConfig } from "../config/config";

const api = axios.create({
  baseURL: envConfig.REACT_APP_BACKEND_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    console.error(error);
    throw error;
  }
);

export default api;
