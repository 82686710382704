import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  sectionWrapper: {
    paddingTop: 80,
    paddingBottom: 80,
  },
  textWrapper: {
    marginBottom: 40,
  },
  title: {
    fontSize: 42,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 24,
    marginTop: 20,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  [theme.breakpoints.down("md")]: {
    title: { fontSize: 38 },
  },
  [theme.breakpoints.down("sm")]: {
    title: { fontSize: 32 },
  },
}));

export default styles;
