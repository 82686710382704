import { Box, Typography } from "@mui/material";
import styles from "./styles";

type Props = {
  title: string;
  description?: string;
};

const InfoCard: React.FC<Props> = ({ title, description }) => {
  const classes = styles();

  return (
    <Box
      className={[classes.infoCard, !description && classes.cardTitleOnly].join(
        " "
      )}
    >
      <Typography
        className={[classes.cardTitle, !description && classes.small].join(" ")}
      >
        {title}
      </Typography>
      {description && (
        <Typography className={classes.cardDescription}>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default InfoCard;
