import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const gradient =
  "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)";

export const CustomSlider = styled(Slider)({
  color: "rgba(255, 255, 255, 0.9)",
  height: 1,
  "& .MuiSlider-track": {
    height: 1,
    color: "#ffffff",
  },
  "& .MuiSlider-thumb": {
    height: 32,
    width: 32,
    backgroundImage: gradient,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:after": {
      height: 30,
      width: 30,
      background: "#2D2D2D",
    },
    "&:before": {
      height: 32,
      width: 32,
      filter: "blur(4px)",
      backgroundImage: gradient,
    },
  },
  "& .MuiSlider-valueLabel": {
    display: "none",
  },
});
