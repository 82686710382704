import { Box, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import api from "../../api/api";
import Faq from "../../components/Faq";
import PlansModal from "../../components/PricingPage/Modal";
import TeamsModal from "../../components/PricingPage/ModalTeams";
import PricingBoard from "../../components/PricingPage/PricingBoard";
import PricingCards from "../../components/PricingPage/PricingCards";
import SectionWrapper from "../../components/SectionWrapper";
import { PlansTemplate } from "../../components/interfaces/Plans";
import { envConfig } from "../../config/config";
import { decimalAdjust } from "../../utils/decimalAdjust";
import plansData from "./cardsInfo.json";
import styles from "./styles";
export interface PriceItemTierProps {
  flat_amount: number;
  flat_amount_decimal: string;
  unit_amount: number;
  unit_amount_decimal: string;
  up_to: number;
}

export interface PriceItemProps {
  id: string;
  currency: string;
  product: string;
  subInterval: "month" | "year";
  taxBehavior: string;
  type: string;
  tiers?: PriceItemTierProps[];
  unit_amount?: number;
}

export type ParsedPricesItem = { [key: number]: number };

export interface ParsedPrices {
  locations: {
    month: ParsedPricesItem;
    year: ParsedPricesItem;
  };
  teams: {
    month: ParsedPricesItem;
    year: ParsedPricesItem;
  };
}

function Plans() {
  const theme = useTheme();
  const navigate = useNavigate();
  const lgUp = useMediaQuery(theme.breakpoints.up("md"));

  const classes = styles();

  const [toggled, setToggled] = useState(false);

  const [currency, setCurrency] = useState("usd");
  const [openModal, setOpenModal] = useState(false);
  const [openTeamsModal, setOpenTeamsModal] = useState(false);
  const [originalPrices, setOriginalPrices] = useState<
    PriceItemProps[] | undefined
  >();
  const [prices, setPrices] = useState<ParsedPrices>({
    locations: {
      month: {},
      year: {},
    },
    teams: {
      month: {},
      year: {},
    },
  });
  const [loading, setLoading] = useState(true);

  const handleSignUp = (zones: number, product?: string) => {
    if (!originalPrices) return;

    const intervalValue = toggled ? "year" : "month";
    const originalPrice = originalPrices.find(
      (e) => e.product === product && e.subInterval === intervalValue
    );

    if (!originalPrice) return;
    const price = (originalPrice.unit_amount || 0) / 10;

    TagManager.dataLayer({
      dataLayer: {
        event: 'add_to_cart',
        subscription: {
          ...originalPrice,
          currency,
          devices: zones,
        },
        product: {
          currency,
          value: price,
          items: [{
            item_id: originalPrice.id,
            item_name: originalPrice.product,
            price,
          }],
        },
      },
    });
    window.location.href = `${envConfig.REACT_APP_FRONTEND_URL}/signup?priceId=${originalPrice.id}&devices=${zones}&currency=${currency}`;
  };

  const handleCardClick = (card: PlansTemplate) => {
    if (card.title === "Locations") {
      setOpenModal(true);
      return null;
    }
    if (card.title === "Teams") {
      setOpenTeamsModal(true);
      return null;
    }
    if (card.title === "Patients") {
      navigate("/contacts");
      return null;
    }
    handleSignUp(1, card.product);
  };

  const handleCurrency = (currency: string) => {
    setCurrency(currency);
  };

  const [pricesList, setPricesList] = useState({
    individual: {
      month: 0,
      year: 0,
    },
    locations: {
      month: 0,
      year: 0,
    },
    teams: {
      month: 0,
      year: 0,
    },
    practitioner: {
      month: 0,
      year: 0,
    },
    patients: {
      month: 0,
      year: 0,
    },
  });

  useEffect(() => {
    setLoading(true);
    api
      .get(`payment/prices?currency=${currency}`)
      .then(({ data }: { data: PriceItemProps[] }) => {
        const locationPrices = data.filter(
          (price) => price.product === "locations"
        );
        const teamsPrices = data.filter((price) => price.product === "teams");
        const payload = {
          locations: {
            month: {},
            year: {},
          },
          teams: {
            month: {},
            year: {},
          },
        };

        locationPrices.forEach((priceItem) => {
          if (!priceItem.tiers) return;
          const result: { [key: number]: number } = {};

          let i = 1;
          for (const item of priceItem.tiers) {
            if (item.up_to) {
              while (i <= item.up_to) {
                result[i] = item.unit_amount / 100;
                i++;
              }
            } else {
              while (result[i]) {
                i++;
              }
              result[i] = item.unit_amount / 100;
            }
          }
          payload.locations[priceItem.subInterval] = result;
        });

        teamsPrices.forEach((priceItem) => {
          if (!priceItem.tiers) return;
          const result: { [key: number]: number } = {};
          let i = 1;
          for (const item of priceItem.tiers) {
            if (item.up_to) {
              while (i <= item.up_to) {
                result[i] = item.unit_amount / 100;
                i++;
              }
            } else {
              while (result[i]) {
                i++;
              }
              result[i] = item.unit_amount / 100;
            }
          }
          payload.teams[priceItem.subInterval] = result;
        });

        setPrices(payload);
        setOriginalPrices(data);
        setPricesCards(data);
        setLoading(false);
      });
  }, [currency]);

  const setPricesCards = (data: PriceItemProps[]) => {
    const payload = {
      individual: {
        month: 0,
        year: 0,
      },
      locations: {
        month: 0,
        year: 0,
      },
      teams: {
        month: 0,
        year: 0,
      },
      practitioner: {
        month: 0,
        year: 0,
      },
      patients: {
        month: 0,
        year: 0,
      },
    };
    data.forEach((object) => {
      if (object.tiers) {
        if (object.product === "locations") {
          const unitAmount = object.tiers.filter((tier) => tier.up_to === 1)[0]
            .unit_amount;
          const fixedUnitAmount = parseFloat(unitAmount.toFixed(2));
          if (object.subInterval === "year") {
            payload.locations.year = parseFloat(
              (fixedUnitAmount / 100 / 12).toString().replace(/[,]/g, ".")
            );
          } else {
            payload.locations.month = parseFloat(
              (fixedUnitAmount / 100).toString().replace(/[,]/g, ".")
            );
          }
        } else {
          const unitAmount = object.tiers.filter((tier) => tier.up_to === 10)[0]
            .unit_amount;
          const fixedUnitAmount = parseFloat(unitAmount.toFixed(2));
          if (object.subInterval === "year") {
            payload.teams.year = parseFloat(
              (fixedUnitAmount / 100 / 12).toString().replace(/[,]/g, ".")
            );
          } else {
            payload.teams.month = parseFloat(
              (fixedUnitAmount / 100).toString().replace(/[,]/g, ".")
            );
          }
        }
      } else if (object.unit_amount) {
        const unitAmount = object.unit_amount;
        const fixedUnitAmount = parseFloat(unitAmount.toFixed(2));
        if (object.product === "individual") {
          if (object.subInterval === "year") {
            payload.individual.year = parseFloat(
              (fixedUnitAmount / 100 / 12).toString().replace(/[,]/g, ".")
            );
            payload.patients.year = parseFloat(
              (fixedUnitAmount / 100 / 12).toString().replace(/[,]/g, ".")
            );
          } else {
            payload.individual.month = parseFloat(
              (fixedUnitAmount / 100).toString().replace(/[,]/g, ".")
            );
            payload.patients.month = parseFloat(
              (fixedUnitAmount / 100).toString().replace(/[,]/g, ".")
            );
          }
        } else {
          if (object.subInterval === "year") {
            payload.practitioner.year = parseFloat(
              (fixedUnitAmount / 100 / 12).toString().replace(/[,]/g, ".")
            );
          } else {
            payload.practitioner.month = parseFloat(
              (fixedUnitAmount / 100).toString().replace(/[,]/g, ".")
            );
          }
        }
      }
    });

    payload.individual = {
      month: decimalAdjust("floor", payload.individual.month, -2) || 0,
      year: decimalAdjust("floor", payload.individual.year, -2) || 0,
    };
    payload.patients = {
      month: decimalAdjust("floor", payload.patients.month, -2) || 0,
      year: decimalAdjust("floor", payload.patients.year, -2) || 0,
    };
    payload.practitioner = {
      month: decimalAdjust("floor", payload.practitioner.month, -2) || 0,
      year: decimalAdjust("floor", payload.practitioner.year, -2) || 0,
    };
    payload.locations = {
      month: decimalAdjust("floor", payload.locations.month, -2) || 0,
      year: decimalAdjust("floor", payload.locations.year, -2) || 0,
    };
    payload.teams = {
      month: decimalAdjust("floor", payload.teams.month, -2) || 0,
      year: decimalAdjust("floor", payload.teams.year, -2) || 0,
    };
    setPricesList(payload);
  };

  return (
    <Box className={classes.pricingWrapper}>
      <Box className={classes.topBackgroundWrapper}>
        <Box className={classes.topBackground} />
      </Box>
      <Box className={classes.pricing}>
        <Container>
          <Typography className={classes.title}>
            Pick the perfect plan for you
          </Typography>
          <Box className={classes.subHeader}>
            <Box className={classes.togglePlans}>
              <Typography>Billed monthly</Typography>
              <Box className={classes.switchWrapper}>
                <label className={classes.switch}>
                  <input
                    onClick={() => {
                      setToggled(!toggled);
                    }}
                    type="checkbox"
                  />
                  <span className={classes.slider}></span>
                </label>
              </Box>
              <Typography>Billed annually</Typography>
            </Box>
            <Typography className={classes.subtitle}>
              Progressive discount applied for multiple subscriptions
            </Typography>
          </Box>
          <PricingCards
            handleCardClick={handleCardClick}
            plans={toggled ? plansData.year : plansData.month}
            toggle={toggled}
            currency={currency}
            handleCurrency={handleCurrency}
            prices={pricesList}
            loading={loading}
          />
          {lgUp && (
            <PricingBoard plans={!toggled ? plansData.month : plansData.year} />
          )}
          <SectionWrapper title="Frequently asked questions">
            <Faq />
          </SectionWrapper>
          {/* <Box className={classes.footerPricing}>
            <Typography>
              Start your 7-day free trial. No payment details required.
            </Typography>
            <Typography>
              Have questions? Reach out to us. We're happy to help!
            </Typography>
          </Box> */}
        </Container>
        <Box className={classes.footerPricing}>
          <Container>
            <Typography>
              Start your 7-day free trial. No payment details required.
            </Typography>
            <Typography>
              Have questions? Reach out to us. We're happy to help!
            </Typography>
          </Container>
        </Box>
      </Box>
      {openModal && (
        <PlansModal
          openAction={openModal}
          closeAction={() => {
            setOpenModal(false);
          }}
          prices={
            toggled ? prices.locations["year"] : prices.locations["month"]
          }
          signUpForPlan={handleSignUp}
          plan={toggled ? "year" : "month"}
          currency={currency}
        />
      )}
      {openTeamsModal && (
        <TeamsModal
          openAction={openTeamsModal}
          signUpForPlan={handleSignUp}
          prices={toggled ? prices.teams["year"] : prices.teams["month"]}
          closeAction={() => {
            setOpenTeamsModal(false);
          }}
          plan={toggled ? "year" : "month"}
          currency={currency}
        />
      )}
    </Box>
  );
}

export default Plans;
