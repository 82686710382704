import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  pricingCards: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  presentationCard: {
    color: "#fff",
    background:
      "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
    borderRadius: 26,
    height: "100%",
  },
  presentationCardTitle: {
    fontSize: "20px",
    fontFamily: "Montserrat Semibold",
  },
  presentationCardDescription: {
    fontSize: "14px",
    marginTop: 10,
  },
  gridPrice: {
    position: "relative",
    zIndex: 1000,
  },
  priceCard: {
    color: "#fff",
    borderRadius: 26,
    height: "100%",
    boxShadow: "2px 1000px 1px #242424 inset",
    backgroundImage:
      "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
    backgroundOrigin: "border-box",
    border: "1px solid transparent",
  },
  priceCardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 170,
  },
  priceCardTitle: {
    fontSize: "20px",
    fontFamily: "Montserrat Medium",
  },
  priceCardValue: {
    fontSize: "36px",
    fontFamily: "Montserrat Semibold",
    display: "flex",
    lineHeight: 1,
    "& span": {
      marginRight: 5,
    },
  },
  priceCardDescription: {
    fontSize: "14px",
    color: "#7e7e7e",
  },
  cardButton: {
    backgroundImage: "linear-gradient(68.02deg, #775DA8 0%, #F06B5D 100%)",
    borderRadius: "26px",
    color: "#FFFFFF",
    fontFamily: "Montserrat Semibold",
    fontSize: "17px",
    lineHeight: "20px",
    padding: "10px 0px",
    width: "100%",
    maxWidth: 130,
    textTransform: "none",
  },
  anualDiscount: {
    backgroundColor: "#9164B6",
    borderRadius: "100%",
    width: "44px",
    height: "44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "-25px",
    right: "25px",
  },
  discountTag: {
    color: "#fff",
    fontFamily: "Montserrat Bold",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    transform: "rotate(20deg)",
  },
  currency: {
    marginTop: 10,
    background: "#ffffff80",
    border: 0,
    borderRadius: 6,
    color: "#fff",
    outline: 0,
    fontFamily: "Montserrat Medium",
    "& .MuiPaper-root": {
      background: "red",
    },
    "& .MuiSelect-select": {
      padding: "2px 10px",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  select: {
    background: "transparent",
    "& ul": {
      backgroundColor: "#000",
      padding: 0,
      borderRadius: 6,
      overflow: "hidden",
      border: "1px solid transparent",
      height: "100%",
      boxShadow: "0px 1000px 0px #242424 inset",
      backgroundImage:
        "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
      backgroundOrigin: "border-box",
    },
    "& li": {
      fontFamily: "Montserrat Medium",
      padding: "2px 10px",
      color: "#fff",
      borderBottom: "1px solid #434343",
      "&:last-child": {
        borderBottom: 0,
      },
      "&:hover": {
        background: "#343434",
      },
      "&.Mui-selected": {
        background: "#434343",
        "&:hover": {
          background: "#343434",
        },
      },
    },
  },

  [theme.breakpoints.down("md")]: {
    presentationCard: {
      textAlign: "center",
    },
  },
}));

export default styles;
