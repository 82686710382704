import { makeStyles } from "@mui/styles";

const styles = makeStyles(() => ({
  title: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "42px",
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "24px",
    textAlign: "center",
    marginBottom: 80,
  },
}));

export default styles;
