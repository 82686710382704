import { Box, Grid, Typography } from "@mui/material";
// import IconAndroid from "../../../assets/images/locationsImg/channel_android.svg";
// import IconApple from "../../../assets/images/locationsImg/channel_apple.svg";
// import IconWeb from "../../../assets/images/locationsImg/channel_web.svg";
// import IconWindows from "../../../assets/images/locationsImg/channel_winblows.svg";

import ExecutivesIcon from "../../../assets/images/teamsImg/briefcase.png";
import CreativeIcon from "../../../assets/images/teamsImg/brush.png";
import SalesIcon from "../../../assets/images/teamsImg/status-up.png";
import AdministrativeIcon from "../../../assets/images/teamsImg/team_administrative.svg";
import LaborIcon from "../../../assets/images/teamsImg/traffic_cone.svg";

import styles from "./styles";

import solution1 from "../../../assets/images/teamsImg/Solutions01.png";
import solution2 from "../../../assets/images/teamsImg/Solutions02.png";
import solution3 from "../../../assets/images/teamsImg/Solutions03.png";

const clusters = [
  { src: solution1, id: 1 },
  { src: solution2, id: 2 },
  { src: solution3, id: 3 },
];

const iconSolutions = [
  { id: 1, src: ExecutivesIcon, name: "Executives" },
  { id: 2, src: AdministrativeIcon, name: "Administrative" },
  { id: 3, src: SalesIcon, name: "Sales" },
  { id: 4, src: LaborIcon, name: "Labor" },
  { id: 5, src: CreativeIcon, name: "Creative" },
];
const Solutions = () => {
  const classes = styles();

  return (
    <Box className={classes.systems}>
      <Box className={classes.icons}>
        {iconSolutions.map((icon) => (
          <Box key={icon.id} className={classes.iconContainer}>
            <img
              src={icon.src}
              alt={`${icon.name} Icon`}
              className={classes.icon}
            />
            <Typography className={classes.text}>{icon.name}</Typography>
          </Box>
        ))}
      </Box>
      <Box className={classes.clusters}>
        <Grid
          container
          spacing={6}
          justifyContent="center"
          className={classes.gridClusterContainer}
        >
          {clusters.map((cluster) => (
            <Grid xs={8} sm={4} item key={cluster.id}>
              <Box className={classes.imageClusterContainer}>
                <img
                  src={cluster.src}
                  alt={`Solution ${cluster.id}`}
                  className={classes.imageCluster}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Solutions;
