import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import CustomButton from "../../../../components/CustomButton";
import PlusSign from "../../../../components/PlusSign";
import styles from "./styles";

type Props = {
  image?: string;
  content?: Array<string>;
  url?: string;
};
const BoardContent: React.FC<Props> = ({ image, content, url }) => {
  const classes = styles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {content?.map((element) => {
            return (
              <Box key={element} sx={{ display: "flex", marginTop: "30px" }}>
                <PlusSign />
                <Typography className={classes.boardText}>{element}</Typography>
              </Box>
            );
          })}
        </Box>
        <div className={classes.buttonBox}>
          <CustomButton background="gray" label="Learn more" url={`/${url}`} />
        </div>
      </Grid>
      {mdUp ? (
        <Grid item xs={6}>
          <img
            className={classes.image}
            src={image}
            alt={image}
            loading="lazy"
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default BoardContent;
