import { Box, Grid } from "@mui/material";
import listener_infograph1 from "../../../assets/images/locationsImg/listener_infograph1.png";
import listener_infograph2 from "../../../assets/images/locationsImg/listener_infograph2.png";
import listener_infograph3 from "../../../assets/images/locationsImg/listener_infograph3.png";
import styles from "./styles";

const listenersImages = [
  { id: 1, src: listener_infograph1 },
  { id: 2, src: listener_infograph2 },
  { id: 3, src: listener_infograph3 },
];

const Listeners = () => {
  const classes = styles();
  return (
    <Box className={classes.listenersContainer}>
      <Box className={classes.listeners}>
        <Grid container spacing={4}>
          {listenersImages.map((img) => (
            <Grid xs={12} sm={4} item key={img.id}>
              <img src={img.src} alt="Listeners 1" className={classes.image} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
export default Listeners;
