import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  buttonBox: {
    display: "flex",
    justifyContent: "left",
    marginTop: "40px",
  },
  boardText: {
    fontFamily: "Montserrat Medium",
    fontSize: "18px",
    lineHeight: "22px",
    marginLeft: 20,
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "end",
  },
  image: {
    width: "580px",
    height: "580px",
    marginTop: "-110px",
    marginBottom: "-110px",
    marginLeft: "10px",
  },

  [theme.breakpoints.down("lg")]: {
    image: {
      marginLeft: "-20px",
    },
  },
  [theme.breakpoints.down(1100)]: {
    image: {
      marginLeft: "-40px",
    },
  },
  [theme.breakpoints.down(1000)]: {
    image: {
      marginLeft: "-60px",
      marginTop: "-80px",
      width: "550px",
      height: "550px",
    },
  },
}));

export default styles;
