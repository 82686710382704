import { Navigate, Route, Routes } from "react-router-dom";
import ContactUs from "./Pages/Contacts";
import Cookies from "./Pages/Cookies";
import Home from "./Pages/Home";
import Individuals from "./Pages/Individuals";
import Locations from "./Pages/Locations";
import Practitioners from "./Pages/Practitioners";
import Pricing from "./Pages/Pricing";
import Privacy from "./Pages/Privacy";
import Science from "./Pages/Science";
import Teams from "./Pages/Teams";
import Terms from "./Pages/Terms";

function SinesRoutes() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/home" />} />
      <Route path="home" element={<Home />} />
      <Route path="science" element={<Science />} />
      <Route path="pricing" element={<Pricing />} />
      <Route path="individuals" element={<Individuals />} />
      <Route path="locations" element={<Locations />} />
      <Route path="patients" element={<Practitioners />} />
      <Route path="practitioners" element={<Practitioners />} />
      <Route path="teams" element={<Teams />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="terms" element={<Terms />} />
      <Route path="cookies" element={<Cookies />} />
      <Route path="contacts" element={<ContactUs />} />
    </Routes>
  );
}

export default SinesRoutes;
