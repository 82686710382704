import styles from "./styles";

function PlusSign() {
  const classes = styles();
  return (
    <>
      <div className={classes.plusSign}>
        <div className={classes.plusSignIcon} />
      </div>
    </>
  );
}

export default PlusSign;
