import { ArrowForwardIos } from "@mui/icons-material";
import { Button } from "@mui/material";
import { FC } from "react";
import styles from "./styles";

interface ICustomButton {
  label: string;
  background?: "none" | "color" | "gray";
  url?: string;
  isChevron?: boolean;
  hasPadding?: boolean;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  /**
   * Extra property required to use any other property of the native component
   */
  [x: string]: string | number | React.ReactNode | unknown;
}

const CustomButton: FC<ICustomButton> = ({
  label,
  background = "color",
  isChevron = false,
  url,
  hasPadding = true,
  type = "button",
  disabled = false,
  ...others
}) => {
  const classes = styles();
  return (
    <Button
      href={url}
      className={[
        classes.customButton,
        background === "gray" && classes.outline,
        background === "color" && classes.backgroundColored,
        hasPadding && classes.buttonPadding,
      ].join(" ")}
      type={type}
      disabled={disabled}
      {...others}
    >
      {label}
      {isChevron && (
        <ArrowForwardIos fontSize="inherit" className={classes.chevron} />
      )}
    </Button>
  );
};

export default CustomButton;
