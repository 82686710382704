import { Box, Container, Typography } from "@mui/material";
import React from "react";

import eyeIcon from "../../assets/images/icons/privacy/vuesaxlineareye-slash.svg";
import keyIcon from "../../assets/images/icons/privacy/vuesaxlinearkey.svg";
import lockerIcon from "../../assets/images/icons/privacy/vuesaxlinearlock.svg";
import securityIcon from "../../assets/images/icons/privacy/vuesaxlinearsecurity-user.svg";
import PageWrapper from "../PageWrapper";
import styles from "./styles";

type Props = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
};

const TermsPrivacyCookies: React.FC<Props> = ({
  title,
  subtitle,
  children,
}) => {
  const classes = styles();
  return (
    <>
      <Box className={classes.privacyBackground}>
        <PageWrapper bgType="privacy">
          <Container>
            <Typography className={classes.title}>{title}</Typography>
            <Box className={classes.icons}>
              <img src={keyIcon} alt="key icon" />
              <img src={securityIcon} alt="safe icon" />
              <img src={eyeIcon} alt="eye icon" />
              <img src={lockerIcon} alt="lock icon" />
            </Box>
            <Typography className={classes.subtitle}>{subtitle}</Typography>
            <Box>{children}</Box>
          </Container>
        </PageWrapper>
      </Box>
    </>
  );
};
export default TermsPrivacyCookies;
