import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: SinesTheme) => ({
  pageTitle: {
    fontSize: 42,
    marginBottom: 20,
  },
  socialMediaIcons: {
    display: "flex",
    columnGap: 20,
    marginBottom: 100,
  },
  socialMediaIcon: {
    height: 34,
  },
  infoBox: {
    padding: 20,
    display: "flex",
    columnGap: 20,
    alignItems: "center",
    marginBottom: 30,
  },
  infoBoxTitle: {
    fontFamily: "Montserrat SemiBold",
    fontSize: 22,
  },
  infoBoxText: {
    fontFamily: "Montserrat",
    fontSize: 16,
  },
  formSelectors: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 26,
    backgroundImage: "linear-gradient(to right, #775DA8, #F06B5D)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // marginBottom: 40,
    padding: 1,
    margin: "0 20px 40px",
  },
  formSelectorsButton: {
    fontSize: 14,
    textTransform: "none",
    fontFamily: "Montserrat SemiBold",
    borderRadius: 0,
    background: "#000",
    width: "100%",
    color: "#f4f4f4",
    padding: "10px 0",
    marginLeft: 0.5,
    marginRight: 0.5,
    "&:first-child": {
      borderTopLeftRadius: "26px",
      borderBottomLeftRadius: "26px",
    },
    "&:last-child": {
      borderTopRightRadius: "26px",
      borderBottomRightRadius: "26px",
    },
    "&.active": {
      background: "#7a5ea7",
    },
    "&:hover": {
      background: "#7a5ea7",
    },
  },
  formBox: {
    padding: "40px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 630,
    position: "relative",
  },
  gradientBorder: {
    background: "rgba(36,36,36,0.7)",
    borderRadius: 26,
    backgroundImage:
      "radial-gradient(circle at 100% 100%, transparent 25px, #7a5ea7 25px, #7a5ea7 26px, transparent 26px), linear-gradient(to right, #7a5ea7, #8f546f), radial-gradient(circle at 0% 100%, transparent 25px, #8f546f 25px, #8f546f 26px, transparent 26px), linear-gradient(to bottom, #8f546f, #7e4852), radial-gradient(circle at 0% 0%, transparent 25px, #7e4852 25px, #7e4852 26px, transparent 26px), linear-gradient(to left, #7e4852, #8d609b), radial-gradient(circle at 100% 0%, transparent 25px, #8d609b 25px, #8d609b 26px, transparent 26px), linear-gradient(to top, #8d609b, #7a5ea7)",
    backgroundSize:
      "26px 26px, calc(100% - 52px) 1px, 26px 26px, 1px calc(100% - 52px)",
    backgroundPosition:
      "top left,top center,top right,center right, bottom right,bottom center,bottom left,center left",
    backgroundRepeat: "no-repeat",
  },
  saveChangesButton: {
    marginTop: 10,
  },
  emailSuccessTitle: {
    fontFamily: "Montserrat Semibold",
    fontSize: 22,
    textAlign: "center",
    marginBottom: 30,
  },
  emailSuccessSubtitle: {
    fontSize: 18,
    textAlign: "center",
  },
  emailError: {
    marginBottom: 20,
    fontSize: 16,
    textAlign: "center",
  },
  //* TABLET - landScape
  [theme.breakpoints.down("lg")]: {},
  //* TABLET - portrait
  [theme.breakpoints.down("md")]: {
    formBox: {
      minHeight: "unset",
    },
  },
  //* MOBILE
  [theme.breakpoints.down("sm")]: {},
}));

export { useStyles };
