import { Box, Typography } from "@mui/material";
import Location from "../../../assets/images/practitioners/location_04.png";
import PatientJourney from "../../../assets/images/practitioners/patient_journey_infograph.png";
import { brainWavesStyle } from "./styles";

function BrainWaves() {
  const classes = brainWavesStyle();

  return (
    <Box className={classes.brainWavesContainer}>
      <Box className={classes.wrapper}>
        <Box className={classes.textWrapper}>
          <Typography className={classes.title}>
            Optimize the entire patient journey
          </Typography>
        </Box>
        <Box className={classes.imgWrapper}>
          <img
            src={PatientJourney}
            alt="Patient Journey"
            loading="lazy"
            className={classes.image}
          />
        </Box>
      </Box>
      <Box className={classes.wrapper}>
        {/* <Box className={classes.imgWrapper}>
          <img
            src={Location}
            alt="Empower Patients + Clients"
            loading="lazy"
            className={classes.image}
          />
        </Box> */}
        <Box className={classes.imageClusterContainer}>
          <img
            src={Location}
            alt="Empower Patients + Clients"
            className={classes.imageCluster}
          />
        </Box>
        <Box className={classes.textWrapper}>
          <Typography className={classes.title}>
            Empower your patients + clients to further their care at home
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
export default BrainWaves;
