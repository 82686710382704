import { Box, Typography } from "@mui/material";
import ListenerGraph from "../../../assets/images/listener_infograph.svg";
import TeamsMeditation from "../../../assets/images/teams_meditations.png";
import { brainWavesStyle } from "./styles";

function BrainWaves() {
  const classes = brainWavesStyle();

  return (
    <Box className={classes.brainWavesContainer}>
      <Box className={classes.wrapper}>
        <Box className={classes.textWrapper}>
          <Typography className={classes.title}>
            A wellness ecosystem for your staff's entire day
          </Typography>
        </Box>
        <Box className={classes.imgWrapper}>
          <img
            src={ListenerGraph}
            alt="Listener's wellness ecosystem"
            loading="lazy"
            className={classes.image}
          />
        </Box>
      </Box>
      <Box className={classes.wrapper}>
        <Box
          className={[classes.imgWrapper, classes.imgWrapperSecond].join(" ")}
        >
          <img
            src={TeamsMeditation}
            alt="Optimal states for performance"
            loading="lazy"
            className={classes.image}
          />
        </Box>
        <Box className={classes.textWrapper}>
          <Typography className={classes.title}>
            Align your team to optimal states for performance
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
export default BrainWaves;
