import { Box, Grid, Typography } from "@mui/material";

import plansDetails from "./features.json";
import styles from "./styles";

import CheckedIcon from "../../../assets/images/icons/check.svg";

interface Plans {
  title: string;
  product: string;
  price: string;
  description: string;
  includes: Array<string>;
}
interface Props {
  plans: Plans[];
}

function IconCheck({ name, plan }: { name: string; plan: Array<string> }) {
  const classes = styles();
  return plan.includes(name) ? (
    <img src={CheckedIcon} alt="" className={classes.icons} />
  ) : null;
}

function PricingBoard({ plans }: Props) {
  const classes = styles();

  return (
    <Box className={classes.pricingBoard}>
      {plansDetails.map((plan) => (
        <Box className={classes.boardSection} key={plan.name}>
          <Box className={classes.boardTitle}>
            <Typography>{plan.name}</Typography>
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Box className={classes.planItems}>
                  {plan.items.map((item) =>
                    item.items === undefined ? (
                      <Typography key={item.name}>{item.name}</Typography>
                    ) : (
                      <Typography className="withInfo" key={item.name}>
                        {item.items.map((str) => (
                          <Typography component="span" key={str.name}>
                            {str.name}
                          </Typography>
                        ))}
                      </Typography>
                    )
                  )}
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Box className={classes.planCheckbox}>
                  {plan.items.map((item) => (
                    <Box
                      key={item.name}
                      className={[
                        classes.checkBoxRow,
                        item.items !== undefined ? "withInfo" : "",
                      ].join(" ")}
                    >
                      {plans.map((feat) => (
                        // <Typography key={feat.title}>{feat.title}</Typography>
                        <Box key={feat.title} className={classes.checkboxItem}>
                          <IconCheck name={item.name} plan={feat.includes} />
                        </Box>
                      ))}
                      {/* <Box className={classes.checkboxItem}>
                        <IconCheck name={item.name} plan={"individuals"} />
                      </Box>
                      <Box className={classes.checkboxItem}>
                        <IconCheck name={item.name} plan={"locations"} />
                      </Box>
                      <Box className={classes.checkboxItem}>
                        <IconCheck name={item.name} plan={"teams"} />
                      </Box>
                      <Box className={classes.checkboxItem}>
                        <IconCheck name={item.name} plan={"practioners"} />
                      </Box>
                      <Box className={classes.checkboxItem}>
                        <IconCheck name={item.name} plan={"patients"} />
                      </Box> */}
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default PricingBoard;
