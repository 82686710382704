import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  imgContainer: {
    textAlign: "center",
  },
  image: {
    maxWidth: "810px",
    width: "100%",
    filter: "drop-shadow(0 0px 20px black)",
  },
}));

export default styles;
