import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import backgroundHeader from "../../assets/images/background_gradients/hero_background.png";
import background from "../../assets/images/background_gradients/midrange_background.png";
const styles = makeStyles((theme: SinesTheme) => ({
  pageWrapper: {
    paddingBottom: 100,
  },
  headerWrapper: {
    backgroundImage: `url(${backgroundHeader})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#0c0c0c",
    backgroundPosition: "bottom",
    paddingBottom: 40,
    paddingTop: 170,
  },
  bodyWrapper: {
    backgroundImage: `url(${background})`,
    backgroundSize: "100% 100vh",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#0c0c0c",
    backgroundPosition: "top",
  },
}));

export default styles;
