import { Container } from "@mui/material";
import LocationImg from "../../assets/images/locations_hero.png";
import Faq from "../../components/Faq";
import InfoCards from "../../components/InfoCards";
import JoinUsFooter from "../../components/JoinUsFooter";
import PageHero from "../../components/PageHero";
import PageWrapperWithHeader from "../../components/PageWrapperWithHeader";
import SectionWrapper from "../../components/SectionWrapper";
import Listeners from "./Listeners";
import Systems from "./Systems";
import WellBeing from "./WellBeing";
import info from "./info.json";

function Locations() {
  return (
    <>
      <PageWrapperWithHeader
        header={
          <Container>
            <PageHero
              title="Turn your entire location into a wellness sanctuary"
              subtitle="Discover the power of binaural music to elevate your space."
              image={LocationImg}
            />
          </Container>
        }
      >
        <Container>
          <SectionWrapper title="Offer clients a wellness experience just by entering your location">
            <Listeners />
          </SectionWrapper>
          <SectionWrapper title="Align your clients + staff with wellbeing">
            <WellBeing info={info.alignWellBeing} />
          </SectionWrapper>

          <SectionWrapper
            title="You don't have to be in the wellness industry to offer peace + harmony in your space."
            subtitle="SINES is for all businesses."
          >
            <InfoCards cards={info.peaceHarmony} />
          </SectionWrapper>

          <SectionWrapper
            title="Integrates with systems you already use"
            subtitle="Connect via WiFi, Bluetooth, Airplay, Ethernet or cables."
          >
            <Systems info={info.systemsInUse} />
          </SectionWrapper>
          <SectionWrapper title="Full location coverage">
            <InfoCards cards={info.fullLocation} />
          </SectionWrapper>
          <SectionWrapper title="Frequently asked questions">
            <Faq info="Locations" stepProp={3} />
          </SectionWrapper>
        </Container>
      </PageWrapperWithHeader>
      <JoinUsFooter finalText="Have questions? Reach out to us. We’re happy to help!" />
    </>
  );
}

export default Locations;
