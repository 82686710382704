import { Box } from "@mui/material";
import React from "react";
import styles from "./styles";

type Props = {
  children?: React.ReactNode;
  header: React.ReactNode;
};

const PageWrapperWithHeader: React.FC<Props> = ({ children, header }) => {
  const classes = styles();
  return (
    <Box className={classes.pageWrapper}>
      <Box className={classes.headerWrapper}>{header}</Box>
      <Box className={classes.bodyWrapper}>{children}</Box>
    </Box>
  );
};

export default PageWrapperWithHeader;
