import { useNavigate } from "react-router-dom";

import { Box, Grid, Link, Typography } from "@mui/material";
import QRCode from "../../assets/images/QRCode.png";
import AppStore from "../../assets/images/appStore.png";
import FacebookIcon from "../../assets/images/icons/social_failbook.svg";
import InstagramIcon from "../../assets/images/icons/social_instagram.svg";
import TwitterIcon from "../../assets/images/icons/social_twitter.svg";
import SinesLogo from "../../assets/images/sines_logo.svg";
import { FooterStyles } from "./styles";

const pagesMobile = [
  { label: "Science", path: "/science" },
  { label: "Individuals", path: "/individuals" },
  { label: "Locations", path: "/locations" },
  { label: "Teams", path: "/teams" },
  { label: "Practitioners + Patients", path: "/patients" },
];

const pagesMobile2 = [
  { label: "Pricing", path: "/pricing" },
  { label: "Terms + Conditions", path: "/terms" },
  { label: "Privacy Policy", path: "/privacy" },
  { label: "Cookie settings", path: "/cookies" },
  { label: "Contact Us", path: "/contacts" },
];

function Footer() {
  const classes = FooterStyles();
  const navigate = useNavigate();
  return (
    <Grid container className={classes.footerGrid}>
      <Grid item xs={12} sm={3} className={classes.logoSection}>
        <div
          onClick={() => {
            navigate("/");
          }}
          className={classes.logoWrapper}
        >
          <img
            className={classes.sinesLogo}
            src={SinesLogo}
            alt="Sines"
            loading="lazy"
          />
          <Typography className={classes.logoSubtitle}>
            © {new Date().getFullYear()} SINES, Inc
          </Typography>
        </div>

        <Box className={classes.socialMediaIconsGrid}>
          <Link href="https://www.facebook.com/sinesglobal/">
            <img
              className={classes.socialMediaIcon}
              src={FacebookIcon}
              alt="facebook"
              loading="lazy"
            />
          </Link>
          <Link href="https://twitter.com/sines_app">
            <img
              className={classes.socialMediaIcon}
              src={TwitterIcon}
              alt="twitter"
              loading="lazy"
            />
          </Link>
          <Link href="https://www.instagram.com/sines.app/">
            <img
              className={classes.socialMediaIcon}
              src={InstagramIcon}
              alt="instagram"
              loading="lazy"
            />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} lg={2} className={classes.menu}>
        <Box>
          {pagesMobile.map((page) => (
            <Typography
              key={page.label}
              className={classes.menuButton}
              onClick={() => {
                navigate(page.path);
              }}
            >
              {page.label}
            </Typography>
          ))}
        </Box>
      </Grid>

      <Grid xs={12} sm={3} lg={2} item className={classes.menu2}>
        <Box>
          {pagesMobile2.map((page) => (
            <Typography
              key={page.label}
              className={classes.menuButton}
              onClick={() => {
                navigate(page.path);
              }}
            >
              {page.label}
            </Typography>
          ))}
        </Box>
      </Grid>

      <Grid item xs={12} sm={3} lg={2} className={classes.menuXS}>
        {pagesMobile.map((page) => (
          <Typography
            key={page.label}
            className={classes.menuButton}
            onClick={() => {
              navigate(page.path);
            }}
          >
            {page.label}
          </Typography>
        ))}
        {pagesMobile2.map((page) => (
          <Typography
            key={page.label}
            className={classes.menuButton}
            onClick={() => {
              navigate(page.path);
            }}
          >
            {page.label}
          </Typography>
        ))}
      </Grid>

      <Grid item xs={12} sm={3} className={classes.qrGrid}>
        <Box className={classes.qrCodeWrapper}>
          <img
            src={QRCode}
            style={{ width: 64, height: 64 }}
            alt="qr-code"
            loading="lazy"
          />
          <a
            href="https://apps.apple.com/app/sines-tuned-to-wellness/id6443797659"
            target="_blank"
            rel="noreferrer"
            className={classes.appStore}
          >
            <img
              src={AppStore}
              style={{ height: "100%", width: "100%" }}
              alt="app-store"
              loading="lazy"
            />
          </a>
        </Box>
      </Grid>
    </Grid>
  );
}
export default Footer;
