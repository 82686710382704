import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { currencies, getCurrency } from "../../../utils/currencies";
import Loading from "../../Loading";
import { PlansTemplate } from "../../interfaces/Plans";
import styles from "./styles";

interface PlansProps {
  plans: PlansTemplate[];
  toggle: boolean;
  handleCardClick: (card: PlansTemplate) => void;
  currency: string;
  handleCurrency: (currency: string) => void;
  prices: IParsedPrices;
  loading: boolean;
}

interface IParsedPrices {
  individual: {
    month: number;
    year: number;
  };
  locations: {
    month: number;
    year: number;
  };
  teams: {
    month: number;
    year: number;
  };

  practitioner: {
    month: number;
    year: number;
  };
  patients: {
    month: number;
    year: number;
  };
}
const PricingCards: React.FC<PlansProps> = ({
  plans,
  toggle,
  handleCardClick,
  currency,
  handleCurrency,
  prices,
  loading,
}) => {
  const [disccountedPrice, setDisccountedPrice] = useState({
    individual: "",
    locations: "",
    teams: "",
    practitioner: "",
    patients: "",
  });

  useEffect(() => {
    const disccountCalc = {
      individual: `${(
        (1 - prices.individual.year / prices.individual.month) *
        100
      ).toFixed(0)}%`,

      locations: `${(
        (1 - prices.locations.year / prices.locations.month) *
        100
      ).toFixed(0)}%`,

      teams: `${((1 - prices.teams.year / prices.teams.month) * 100).toFixed(
        0
      )}%`,

      practitioner: `${(
        (1 - prices.practitioner.year / prices.practitioner.month) *
        100
      ).toFixed(0)}%`,

      patients: `${(
        (1 - prices.patients.year / prices.patients.month) *
        100
      ).toFixed(0)}%`,
    };
    setDisccountedPrice(disccountCalc);
  }, [prices]);
  const classes = styles();

  function getPrice(plan: string, isMonth: boolean = true) {
    plan = plan.toLowerCase();
    switch (plan) {
      case "individual":
        return isMonth ? prices.individual.month : prices.individual.year;
      case "locations":
        return isMonth ? prices.locations.month : prices.locations.year;
      case "teams":
        return isMonth ? prices.teams.month : prices.teams.year;
      case "practitioner":
        return isMonth ? prices.practitioner.month : prices.practitioner.year;
      case "patients":
        return isMonth ? prices.patients.month : prices.patients.year;
      default:
        break;
    }
  }
  function getDiscount(plan: string) {
    plan = plan.toLowerCase();
    switch (plan) {
      case "individual":
        return disccountedPrice.individual;
      case "locations":
        return disccountedPrice.locations;
      case "teams":
        return disccountedPrice.teams;
      case "practitioner":
        return disccountedPrice.practitioner;
      case "patients":
        return disccountedPrice.patients;
      default:
        break;
    }
  }
  const currencyHandler = (event: SelectChangeEvent) => {
    handleCurrency(event.target.value);
  };

  return (
    <>
      <Box className={classes.pricingCards}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={2}>
            <Card className={classes.presentationCard}>
              <CardContent>
                <Typography className={classes.presentationCardTitle}>
                  Our Plans + Features
                </Typography>
                <Typography className={classes.presentationCardDescription}>
                  Find the subscription that makes the most sense for you or
                  your team
                </Typography>
                <Select
                  fullWidth
                  value={currency}
                  onChange={currencyHandler}
                  className={classes.currency}
                  IconComponent={ExpandMore}
                  id="currencySelector"
                  MenuProps={{
                    classes: { paper: classes.select },
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  {currencies.map((currency) => (
                    <MenuItem value={currency.id} key={currency.id}>
                      {currency.name}
                    </MenuItem>
                  ))}
                </Select>
              </CardContent>
            </Card>
          </Grid>
          {plans.map((card) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              key={card.title}
              className={classes.gridPrice}
            >
              {toggle && (
                <Box className={classes.anualDiscount}>
                  <Typography className={classes.discountTag}>
                    {getDiscount(card.product)}
                  </Typography>
                </Box>
              )}
              <Card className={classes.priceCard}>
                <CardContent sx={{ height: "100%" }}>
                  <Box className={classes.priceCardContent}>
                    <Typography className={classes.priceCardTitle}>
                      {card.title}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography className={classes.priceCardValue}>
                        <Typography component="span">
                          {getCurrency(currency)?.symbol}
                        </Typography>
                        {getPrice(card.product, !toggle)}
                      </Typography>
                      <Typography className={classes.priceCardDescription}>
                        {card.description}
                      </Typography>
                    </Box>
                    <Button
                      className={classes.cardButton}
                      onClick={() => handleCardClick(card)}
                    >
                      {card.title === "Patients" ? "Contact us" : "Sign up"}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {loading && <Loading />}
    </>
  );
};

export default PricingCards;
