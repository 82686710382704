import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const TextandImageStyle = makeStyles((theme: SinesTheme) => ({
  intro: {
    paddingBottom: 100,
    color: "#fff",
  },
  mainTitle: {
    fontSize: "42px",
    lineHeight: "50px",
    textAlign: "left",
  },
  mainSubTitle: {
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "left",
    paddingTop: "44px",
  },
  buttonDiv: {
    width: "446px",
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    marginTop: "79px",
  },
  homePageImg: {
    maxWidth: "920px",
    position: "absolute",
    top: -50,
    left: 20,
  },
  heroImage: {
    position: "relative",
  },
  mobileHomePageImg: {
    maxWidth: "330px",
    position: "absolute",
    top: -70,
    left: 20,
  },
  [theme.breakpoints.down("md")]: {
    textWrapper: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
    },
    mainTitle: {
      textAlign: "center",
    },
    mainSubTitle: {
      textAlign: "center",
    },
  },
  [theme.breakpoints.down("sm")]: {
    mainTitle: {
      textAlign: "left",
    },
    mainSubTitle: {
      textAlign: "left",
    },
    textWrapper: {
      display: "block",
      textAlign: "left",
      alignItems: "left",
      justifyContent: "left",
    },
  },
}));

export { TextandImageStyle };
