import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const styles = makeStyles((theme: SinesTheme) => ({
  wellBeing: {},
  textPlusContainer: {
    display: "flex",
    margin: "40px 0",
    alignItems: "center",
    "& p": {
      fontFamily: "Montserrat Medium",
      fontSize: 18,
      marginLeft: 20,
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "end",
  },
  imageBlurContainer: {
    // display: "flex",
    // justifyContent: "right",
    position: "relative",
    height: "100%",
    maxHeight: 400,
    maxWidth: 400,
    "&:before": {
      content: "''",
      backgroundImage:
        "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
      filter: "blur(30px)",
      top: "-1%",
      bottom: "-1%",
      left: "-1%",
      right: "-1%",
      borderRadius: 26,
      position: "absolute",
      zIndex: -1,
    },
  },
  image: {
    width: "100%",
    maxHeight: "100%",
    borderRadius: 26,
    zIndex: 1,
    objectFit: "cover",
    // opacity: 0.1,
  },
}));

export default styles;
