import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import location from "../../../assets/images/locationsImg/location_04.png";
import PlusSign from "../../../components/PlusSign";
import styles from "./styles";

type Props = {
  info: Array<string>;
};
const WellBeing: React.FC<Props> = ({ info }) => {
  const classes = styles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box className={classes.wellBeing}>
      <Grid container>
        <Grid item sm={6} md={6}>
          {info.map((str) => {
            return (
              <Box className={classes.textPlusContainer} key={str}>
                <PlusSign />
                <Typography>{str}</Typography>
              </Box>
            );
          })}
        </Grid>
        {smUp && (
          <Grid item xs={3} sm={6} md={6}>
            <Box className={classes.imageContainer}>
              <Box className={classes.imageBlurContainer}>
                <img className={classes.image} src={location} alt="Intro" />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default WellBeing;
