import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  image: {
    maxWidth: "100%",
    width: "100%",
    filter: "drop-shadow(0 0px 20px black)",
  },
  listeners: {},
  [theme.breakpoints.down("sm")]: {
    listenersContainer: {
      textAlign: "center",
    },
    image: { maxWidth: "70%" },
  },
}));

export default styles;
