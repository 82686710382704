import { Button, Dialog, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrency } from "../../../utils/currencies";
import { CustomSlider } from "../../CustomSlider";
import { ParsedPricesItem } from "../../interfaces/Plans";
import { modalStyle } from "./styles";

interface ModalProps {
  openAction: boolean;
  plan: string;
  closeAction: () => void;
  signUpForPlan: (zones: number, product: string) => void;
  prices: ParsedPricesItem;
  currency: string;
}

const PlansModal: React.FC<ModalProps> = ({
  openAction,
  plan,
  closeAction,
  signUpForPlan,
  prices,
  currency,
}) => {
  const classes = modalStyle();
  const [value, setValue] = useState<number>(1);
  const navigate = useNavigate();

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    }
  };

  useEffect(() => {
    return () => {
      setValue(1);
    };
  }, [openAction]);

  const priceMonth = prices[value]?.toFixed(2);
  const priceYear = (prices[value] / 12).toFixed(2);

  const savingMonth = (prices[1] - prices[value])?.toFixed(2);
  const savingYear = ((prices[1] - prices[value]) / 12)?.toFixed(2);

  const savingMonthTimesZones = ((prices[1] - prices[value]) * value)?.toFixed(
    2
  );
  const savingYearTimesZones = (
    ((prices[1] - prices[value]) / 12) *
    value
  ).toFixed(2);

  const totalPriceMonth = (prices[value] * value)?.toFixed(2);
  const totalPriceYear = ((prices[value] * value) / 12)?.toFixed(2);

  return (
    <Dialog
      className={classes.modalStyle}
      open={openAction}
      onClose={closeAction}
    >
      <div>
        <Grid>
          <Grid className={classes.titleGrid}>
            <Typography className={classes.modalTitle}>
              How many zones do you want to stream to?
            </Typography>
          </Grid>
          <Grid className={classes.sliderGrid}>
            <CustomSlider
              min={1}
              step={1}
              max={21}
              onChange={handleChange}
              defaultValue={1}
              className={value === 21 ? classes.sliderWarning : ""}
            />
            <Grid className={classes.sliderCounter}>
              <Box className={classes.sliderScale}>
                <Typography className={classes.sliderNumber}>
                  {value === 21 ? "20+" : value}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {value !== 21 && (
            <Grid sx={{ height: "110px" }}>
              <Grid className={classes.priceGrid}>
                <Grid sx={{ width: "50%" }}>
                  <Typography className={classes.priceTitle}>
                    Price per streaming zone
                  </Typography>
                  <Typography className={classes.priceSubtitle}>
                    {`Saving ${getCurrency(currency)?.symbol}${
                      plan === "month" ? savingMonth : savingYear
                    } /zone`}
                    {/* Saving ${plan === "month" ? savingMonth : savingYear} /zone */}
                  </Typography>
                </Grid>
                <Grid sx={{ width: "50%" }}>
                  <Typography
                    className={classes.priceTitle}
                    sx={{ textAlign: "end" }}
                  >
                    {`${getCurrency(currency)?.symbol}${
                      plan === "month" ? priceMonth : priceYear
                    } /month`}
                  </Typography>
                  <Typography
                    className={classes.priceSubtitle}
                    sx={{ textAlign: "end" }}
                  >
                    {`${getCurrency(currency)?.symbol}${
                      plan === "month"
                        ? savingMonthTimesZones
                        : savingYearTimesZones
                    } /month
                    `}
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={classes.totalPriceContainer}>
                <Grid className={classes.totalPriceGrid}>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    className={classes.totalPriceText}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{ marginRight: "20px" }}
                    className={classes.totalPriceText}
                  >
                    <>
                      {`${getCurrency(currency)?.symbol}${
                        plan === "month" ? totalPriceMonth : totalPriceYear
                      } /month`}
                    </>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {value === 21 && (
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "110px",
              }}
            >
              <Grid className={classes.totalPriceGridWarning}>
                <Grid className={classes.titleTeamsGrid}>
                  <Typography className={classes.totalPriceTitle}>
                    Need more than 20 zones?
                  </Typography>
                </Grid>
                <Grid className={classes.teamsTextGrid}>
                  <Typography className={classes.teamsText}>
                    If so, please contact us so we can help you find the best
                    plan for your business.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid>
            <Grid className={classes.cardBtnGrid}>
              <Button
                onClick={
                  value === 21
                    ? () => {
                        navigate("/contacts?topic=sales");
                      }
                    : () => signUpForPlan(value, "locations")
                }
                className={classes.cardButton}
              >
                {value === 21 ? "Contact sales" : "Sign up"}
              </Button>
            </Grid>
            {value === 21 ? null : (
              <Grid className={classes.btnSubtitleGrid}>
                <Typography className={classes.buttonSubtitle}>
                  All plans include a 7-day free trial
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};
export default PlansModal;
