import { Box, Container, Grid, Typography } from "@mui/material";
import CustomButton from "../CustomButton";
import styles from "./styles";

const IndividualsFooter: React.FC = () => {
  const classes = styles();
  return (
    <Box sx={{ padding: "80px 0" }} className={classes.wrapperBg}>
      <Container maxWidth="lg">
        <Typography className={classes.title}>
          Looking for solutions for your <br /> business?
        </Typography>
        <Box
          sx={{
            marginTop: 10,
            marginBottom: 1,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Box className={classes.buttonsWrapper}>
            <Box className={classes.buttonWrapper}>
              <CustomButton
                label="Locations"
                url="/locations"
                background="gray"
              />
            </Box>
            <Box className={classes.buttonWrapper}>
              <CustomButton label="Teams" url="/teams" background="gray" />
            </Box>
            <Box className={classes.buttonWrapper}>
              <CustomButton
                label="Practitioners"
                url="/practitioners"
                background="gray"
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default IndividualsFooter;
