import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  cardWrapper: {
    backgroundColor: "#242424",
    borderRadius: "26px",
    padding: "20px 20px",
    border: "solid 1px transparent",
    boxShadow: "0px 10000px 0px #242424 inset",
    backgroundImage:
      "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
    backgroundOrigin: "border-box",
  },
  card: {
    backgroundColor: "#242424",
    borderRadius: "29px",
    width: "548px",
    height: "fit-content",
    marginTop: "1px",
    marginBottom: "1px",
  },
  infoText: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "20px",
    textAlign: "left",
    marginBottom: 40,
  },
  sourceTitle: {
    color: "#FFFFFF",
    fontFamily: "Montserrat SemiBold",
    fontSize: "14px",
    textAlign: "left",
  },
  sourceText: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "14px",
    opacity: "0.6",
    textAlign: "left",
    marginBottom: 10,
    textDecoration: "none",
  },
}));

export default styles;
