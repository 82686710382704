import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  infoCard: {
    backgroundColor: "#242424",
    borderRadius: "26px",
    padding: "20px 15px 20px 20px",
    border: "solid 1px transparent",
    boxShadow: "0px 1000px 0px #242424 inset",
    backgroundImage:
      "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
    backgroundOrigin: "border-box",
    height: "100%",
    minHeight: 82,
    position: "relative",
    zIndex: 2,
    "&:before": {
      content: "''",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: 26,
      position: "absolute",
      zIndex: -1,
      backgroundColor: "#242424",
    },
    "&:after": {
      content: "''",
      backgroundImage:
        "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
      filter: "blur(10px)",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: 26,
      position: "absolute",
      zIndex: -2,
    },
  },
  cardTitleOnly: {
    display: "flex",
    alignItems: "center",
  },
  cardTitle: {
    fontFamily: "Montserrat Semibold",
    fontSize: "24px",
    lineHeight: 1,
  },
  small: {
    fontSize: 20,
    lineHeight: 1.2,
  },
  cardDescription: {
    marginTop: 20,
    fontSize: "16px",
  },
  [theme.breakpoints.down("md")]: {
    title: {
      fontSize: "30px",
      textAlign: "center",
    },
  },
}));

export default styles;
