import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const brainWavesStyle = makeStyles((theme: SinesTheme) => ({
  brainWavesContainer: {
    // border: "1px solid #fff",
    borderRadius: 26,
    padding: "20px 60px",
  },
  wrapper: {
    display: "flex",
    marginBottom: 10,
  },
  textWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    flex: "50%",
    padding: "0 40px",
  },
  title: {
    fontSize: "42px",
    lineHeight: "50px",
    textAlign: "left",
  },
  imgWrapper: {
    flex: "50%",
  },
  image: {
    maxWidth: "100%",
  },

  imageCluster: {
    maxWidth: 474,
    width: "100%",
    filter: "drop-shadow(0 0px 20px black)",
    borderRadius: 26,
    objectFit: "cover",
    height: "100%",
  },
  imageClusterContainer: {
    maxWidth: 474,
    position: "relative",
    height: "100%",
    textAlign: "center",
    margin: "0 auto",
    "&:before": {
      content: "''",
      backgroundImage:
        "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
      filter: "blur(30px)",
      top: -5,
      bottom: -5,
      left: -5,
      right: -5,
      borderRadius: 26,
      position: "absolute",
    },
  },
  [theme.breakpoints.down("md")]: {
    title: {
      fontSize: "30px",
      textAlign: "center",
    },
    wrapper: {
      flexDirection: "column",
    },
    textWrapper: {
      flex: "100%",
      padding: 0,
    },
    imgWrapper: {
      alignSelf: "center",
      textAlign: "center",
    },
    image: {
      maxHeight: 270,
    },
    imageCluster: {
      maxHeight: 270,
    },
  },
  [theme.breakpoints.down("sm")]: {
    title: {
      fontSize: "25px",
    },
    image: {
      maxHeight: 200,
    },
    imageCluster: {
      maxHeight: 200,
    },
  },
}));

export { brainWavesStyle };
