import { Box, Container, Grid } from "@mui/material";
import TeamsImg from "../../assets/images/teamsImg/teams_hero.png";
import CustomButton from "../../components/CustomButton";
import Faq from "../../components/Faq";
import InfoCard from "../../components/InfoCard";
import InfoCards from "../../components/InfoCards";
import JoinUsFooter from "../../components/JoinUsFooter";
import PageHero from "../../components/PageHero";
import PageWrapperWithHeader from "../../components/PageWrapperWithHeader";
import SectionWrapper from "../../components/SectionWrapper";
import BrainWaves from "./BrainWaves";
import Solutions from "./Solutions";
import info from "./info.json";

function Teams() {
  return (
    <>
      <PageWrapperWithHeader
        header={
          <Container>
            <PageHero
              title="We empower teams to achieve wellbeing"
              subtitle="Discover the power of binaural music + guided meditations to support your staff."
              image={TeamsImg}
            />
          </Container>
        }
      >
        <Container>
          <SectionWrapper title="Elevate employee mental health + wellness">
            <InfoCards cards={info.cards}>
              <Box sx={{ textAlign: "center", marginTop: 8 }}>
                <CustomButton
                  label="Learn More"
                  background="gray"
                  url="/pricing"
                />
              </Box>
            </InfoCards>
          </SectionWrapper>
          <SectionWrapper title="Solutions for everyone">
            <Solutions />
          </SectionWrapper>
          <SectionWrapper>
            <BrainWaves />
          </SectionWrapper>
          <SectionWrapper title="Intuitive. Turn-key. Easy to navigate.">
            <Grid container spacing={4}>
              {info.intuitiveSection.map((card) => (
                <Grid item xs={12} sm={4} lg={4} key={card.title}>
                  <InfoCard title={card.title} />
                </Grid>
              ))}
            </Grid>
          </SectionWrapper>
          <SectionWrapper title="Frequently asked questions">
            <Faq info="Teams" stepProp={4} />
          </SectionWrapper>
        </Container>
      </PageWrapperWithHeader>
      <JoinUsFooter finalText="If you need a program that's custom to your business, our team can help" />
    </>
  );
}

export default Teams;
