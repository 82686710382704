import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  inputCustom: {},
  input: {
    fontFamily: "Montserrat Medium",
    padding: "10px 18px",
    color: "#fff",
    "&:-webkit-autofill": {
      // WebkitBoxShadow: "0 0 0 1000px #2A2A2A inset",
      WebkitTextFillColor: "#FFFFFF",
      WebkitBackgroundClip: "text",
    },
  },
}));

export { useStyles };
