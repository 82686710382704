import { Box, Grid } from "@mui/material";
import cluster1 from "../../../assets/images/individualsImg/image_cluster01.png";
import cluster2 from "../../../assets/images/individualsImg/image_cluster02.png";
import cluster3 from "../../../assets/images/individualsImg/image_cluster03.png";
import cluster4 from "../../../assets/images/individualsImg/image_cluster04.png";
import cluster5 from "../../../assets/images/individualsImg/image_cluster05.png";
import cluster6 from "../../../assets/images/individualsImg/image_cluster06.png";
import cluster7 from "../../../assets/images/individualsImg/image_cluster07.png";
import phone1 from "../../../assets/images/individualsImg/mobileHeaderImage.png";
import phone2 from "../../../assets/images/individualsImg/optimizeday_phone02.png";
import phone3 from "../../../assets/images/individualsImg/optimizeday_phone03.png";
import styles from "./styles";

const clusters = [
  { src: cluster1, id: 1 },
  { src: cluster2, id: 2 },
  { src: cluster3, id: 3 },
  { src: cluster4, id: 4 },
  { src: cluster5, id: 5 },
  { src: cluster6, id: 6 },
  { src: cluster7, id: 7 },
];
const ImageSection = () => {
  const classes = styles();
  return (
    <Box className={classes.optimizeContainer}>
      <Box className={classes.phones}>
        <Grid container>
          <Grid xs={4} item>
            <img
              src={phone1}
              alt="Optimize Phone 1"
              className={classes.image}
            />
          </Grid>
          <Grid xs={4} item>
            <img
              src={phone2}
              alt="Optimize Phone 2"
              className={classes.image}
            />
          </Grid>
          <Grid xs={4} item>
            <img
              src={phone3}
              alt="Optimize Phone 3"
              className={classes.image}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.clusters}>
        <Grid container spacing={6} justifyContent="center">
          {clusters.map((cluster) => (
            // <Grid xs={10} sm={6} md={5} lg={4} item key={cluster.id}>
            //   <Box className={classes.imageClusterContainer}>
            //     <img
            //       src={cluster.src}
            //       alt={`Optimize Cluster ${cluster.id}`}
            //       className={classes.imageCluster}
            //     />
            //   </Box>
            // </Grid>
            <Grid xs={6} sm={4} md={3} lg={3} item key={cluster.id}>
              <Box className={classes.imageClusterContainer}>
                <img
                  src={cluster.src}
                  alt={`Optimize Cluster ${cluster.id}`}
                  className={classes.imageCluster}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
export default ImageSection;
