import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CollapseIcon from "../../assets/images/icons/collapse.svg";
import ExpandIcon from "../../assets/images/icons/expand.svg";
import data from "./questions.json";
import { faqStyles } from "./styles";

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <div className="expandIconWrapper">
        <img src={CollapseIcon} alt="collapseIcon" />
      </div>
      <div className="collapsIconWrapper">
        <img src={ExpandIcon} alt="expandIcon" />
      </div>
    </Box>
  );
};

interface IFaq {
  info?: string;
  stepProp?: number;
}

const Faq: React.FC<IFaq> = ({ info, stepProp }) => {
  const classes = faqStyles();
  const [step, setStep] = useState(stepProp || 1);
  const [selected, setSelected] = useState(info || "General");

  const faqItems = [
    { name: "General", step: 1 },
    { name: "Individuals", step: 2 },
    { name: "Locations", step: 3 },
    { name: "Teams", step: 4 },
    { name: "Practitioners", step: 5 },
    //{ name: "Patients", step: 6 },
  ];
  return (
    <Grid>
      {/* <Typography className={classes.faqTitle}>
        Frequently asked questions
      </Typography> */}
      <Grid sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box className={classes.faqNavbarWrapper}>
          <Box className={classes.faqNavbar}>
            {faqItems.map((element) => {
              return (
                <Button
                  className={
                    selected === element.name
                      ? classes.selectedButton
                      : classes.faqButton
                  }
                  key={element.name}
                  onClick={() => {
                    setStep(element.step);
                    setSelected(element.name);
                  }}
                >
                  {element.name}
                </Button>
              );
            })}
          </Box>
        </Box>
      </Grid>
      <Grid id="smallScreenAccordeon" className={classes.smallScreenAccordeon}>
        <Accordion className={classes.faqAccordion}>
          <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="accordion-content"
          >
            <Typography className={classes.faqAcordeonTitle}>
              Individuals
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {data.questions
                .filter((e) => {
                  return e.category === "Individuals";
                })
                .map((question) => {
                  return (
                    <Box
                      sx={{ marginBottom: "40px" }}
                      key={question.questionNumber}
                    >
                      <Box className={classes.faqQuestionWrapper}>
                        <Typography
                          className={classes.faqQuestion}
                          sx={{
                            fontSize:
                              question.question.length > 90 ? "14px" : "16px",
                          }}
                        >
                          {question.question}
                        </Typography>
                      </Box>
                      <Typography className={classes.faqText}>
                        {question.answer}
                      </Typography>
                    </Box>
                  );
                })}
            </div>
            <Typography className={classes.faqText}></Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.faqAccordion}>
          <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="accordion-content"
          >
            <Typography className={classes.faqAcordeonTitle}>
              Locations
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {data.questions
                .filter((e) => {
                  return e.category === "Locations";
                })
                .map((question) => {
                  return (
                    <Box
                      sx={{ marginBottom: "40px" }}
                      key={question.questionNumber}
                    >
                      <Box className={classes.faqQuestionWrapper}>
                        <Typography
                          className={classes.faqQuestion}
                          sx={{
                            fontSize:
                              question.question.length > 90 ? "14px" : "16px",
                          }}
                        >
                          {question.question}
                        </Typography>
                      </Box>
                      <Typography className={classes.faqText}>
                        {question.answer}
                      </Typography>
                    </Box>
                  );
                })}
            </div>
            <Typography className={classes.faqText}></Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.faqAccordion}>
          <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="accordion-content"
          >
            <Typography className={classes.faqAcordeonTitle}>
              Practitioners
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {data.questions
                .filter((e) => {
                  return e.category === "Practitioners";
                })
                .map((question) => {
                  return (
                    <Box
                      sx={{ marginBottom: "40px" }}
                      key={question.questionNumber}
                    >
                      <Box className={classes.faqQuestionWrapper}>
                        <Typography
                          className={classes.faqQuestion}
                          sx={{
                            fontSize:
                              question.question.length > 90 ? "14px" : "16px",
                          }}
                        >
                          {question.question}
                        </Typography>
                      </Box>
                      <Typography className={classes.faqText}>
                        {question.answer}
                      </Typography>
                    </Box>
                  );
                })}
            </div>
            <Typography className={classes.faqText}></Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.faqAccordion}>
          <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="accordion-content"
          >
            <Typography className={classes.faqAcordeonTitle}>Teams</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {data.questions
                .filter((e) => {
                  return e.category === "Teams";
                })
                .map((question) => {
                  return (
                    <Box
                      sx={{ marginBottom: "40px" }}
                      key={question.questionNumber}
                    >
                      <Box className={classes.faqQuestionWrapper}>
                        <Typography
                          className={classes.faqQuestion}
                          sx={{
                            fontSize:
                              question.question.length > 90 ? "14px" : "16px",
                          }}
                        >
                          {question.question}
                        </Typography>
                      </Box>
                      <Typography className={classes.faqText}>
                        {question.answer}
                      </Typography>
                    </Box>
                  );
                })}
            </div>
            <Typography className={classes.faqText}></Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {step === 1 && (
        <Box className={classes.questionsBox}>
          {data.questions
            .filter((e) => {
              return e.category === "General";
            })
            .map((question) => {
              return (
                <Grid
                  key={question.questionNumber}
                  className={classes.accordionGrid}
                >
                  <Accordion className={classes.faqAccordion}>
                    <AccordionSummary
                      expandIcon={<CustomExpandIcon />}
                      aria-controls="accordion-content"
                      id={"accordion-header" + question.questionNumber}
                      className={classes.panelSummary}
                    >
                      <Box className={classes.faqQuestionWrapper}>
                        <Typography
                          className={classes.faqQuestion}
                          sx={{
                            fontSize:
                              question.question.length > 90 ? "14px" : "16px",
                          }}
                        >
                          {question.question}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.faqText}>
                        {question.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
        </Box>
      )}
      {step === 2 && (
        <Box className={classes.questionsBox}>
          {data.questions
            .filter((e) => {
              return e.category === "Individuals";
            })
            .map((question) => {
              return (
                <Grid
                  key={question.questionNumber}
                  className={classes.accordionGrid}
                >
                  <Accordion className={classes.faqAccordion}>
                    <AccordionSummary
                      expandIcon={<CustomExpandIcon />}
                      aria-controls="accordion-content"
                      id={"accordion-header" + question.questionNumber}
                      className={classes.panelSummary}
                    >
                      <Box className={classes.faqQuestionWrapper}>
                        <Typography
                          className={classes.faqQuestion}
                          sx={{
                            fontSize:
                              question.question.length > 90 ? "14px" : "16px",
                          }}
                        >
                          {question.question}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.faqText}>
                        {question.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
        </Box>
      )}
      {step === 4 && (
        <Box className={classes.questionsBox}>
          {data.questions
            .filter((e) => {
              return e.category === "Teams";
            })
            .map((question) => {
              return (
                <Grid
                  key={question.questionNumber}
                  className={classes.accordionGrid}
                >
                  <Accordion className={classes.faqAccordion}>
                    <AccordionSummary
                      expandIcon={<CustomExpandIcon />}
                      aria-controls="accordion-content"
                      id={"accordion-header" + question.questionNumber}
                      className={classes.panelSummary}
                    >
                      <Box className={classes.faqQuestionWrapper}>
                        <Typography
                          className={classes.faqQuestion}
                          sx={{
                            fontSize:
                              question.question.length > 90 ? "14px" : "16px",
                          }}
                        >
                          {question.question}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.faqText}>
                        {question.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
        </Box>
      )}
      {step === 5 && (
        <Box className={classes.questionsBox}>
          {data.questions
            .filter((e) => {
              return e.category === "Practitioners";
            })
            .map((question) => {
              return (
                <Grid
                  key={question.questionNumber}
                  className={classes.accordionGrid}
                >
                  <Accordion className={classes.faqAccordion}>
                    <AccordionSummary
                      expandIcon={<CustomExpandIcon />}
                      aria-controls="accordion-content"
                      id={"accordion-header" + question.questionNumber}
                      className={classes.panelSummary}
                    >
                      <Box className={classes.faqQuestionWrapper}>
                        <Typography
                          className={classes.faqQuestion}
                          sx={{
                            fontSize:
                              question.question.length > 90 ? "14px" : "16px",
                          }}
                        >
                          {question.question}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.faqText}>
                        {question.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
        </Box>
      )}
      {step === 3 && (
        <Box className={classes.questionsBox}>
          {data.questions
            .filter((e) => {
              return e.category === "Locations";
            })
            .map((question) => {
              return (
                <Grid
                  key={question.questionNumber}
                  className={classes.accordionGrid}
                >
                  <Accordion className={classes.faqAccordion}>
                    <AccordionSummary
                      expandIcon={<CustomExpandIcon />}
                      aria-controls="accordion-content"
                      id={"accordion-header" + question.questionNumber}
                      className={classes.panelSummary}
                    >
                      <Box className={classes.faqQuestionWrapper}>
                        <Typography
                          className={classes.faqQuestion}
                          sx={{
                            fontSize:
                              question.question.length > 90 ? "14px" : "16px",
                          }}
                        >
                          {question.question}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.faqText}>
                        {question.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
        </Box>
      )}
    </Grid>
  );
};

export default Faq;
