import { Box } from "@mui/material";
import React from "react";
import styles from "./styles";

type Props = {
  children?: React.ReactNode;
  bgType?: string;
};

const PageWrapper: React.FC<Props> = ({ children, bgType }) => {
  const classes = styles();
  return (
    <Box
      className={[
        classes.pageWrapper,
        bgType === "privacy" ? classes.privacyBG : "",
      ].join(" ")}
    >
      {children}
    </Box>
  );
};

export default PageWrapper;
