import React, { FC } from "react";
import { Dialog, DialogProps } from "@mui/material";
import Lottie from "lottie-react";
import styled from "@emotion/styled";
import groovyWalkAnimation from "./loading.json";
import { useStyles } from "./styles";

const BlurryDialog = styled(Dialog)<DialogProps>(() => ({
  backdropFilter: "blur(6px)",
}));

const Loading: FC = () => {
  const classes = useStyles();

  return (
    <BlurryDialog
      sx={{ zIndex: 1401 }}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      open
    >
      <Lottie
        style={{
          zIndex: 1401,
          width: "100px",
        }}
        animationData={groovyWalkAnimation}
        loop
      />
    </BlurryDialog>
  );
};

export default Loading;
