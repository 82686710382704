import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const cardSectionStyle = makeStyles((theme: SinesTheme) => ({
  supportContainer: {
    textAlign: "center",
  },
  title: {
    fontSize: "42px",
    marginBottom: 40,
  },
  image: {
    maxWidth: "810px",
    width: "100%",
    filter: "drop-shadow(0 0px 20px black)",
  },
  [theme.breakpoints.down("md")]: {
    title: {
      fontSize: "30px",
      textAlign: "center",
      marginBottom: 60,
    },
  },
}));

export { cardSectionStyle };
