import { Box, Grid } from "@mui/material";
import cluster1 from "../../../assets/images/practitioners/pp_image_cluster01.png";
import cluster2 from "../../../assets/images/practitioners/pp_image_cluster02.png";
import cluster3 from "../../../assets/images/practitioners/pp_image_cluster03.png";
import cluster4 from "../../../assets/images/practitioners/pp_image_cluster04.png";
import cluster5 from "../../../assets/images/practitioners/pp_image_cluster05.png";
import cluster6 from "../../../assets/images/practitioners/pp_image_cluster06.png";
import styles from "./styles";

const clusters = [
  { src: cluster1, id: 1 },
  { src: cluster2, id: 2 },
  { src: cluster3, id: 3 },
  { src: cluster4, id: 4 },
  { src: cluster5, id: 5 },
  { src: cluster6, id: 6 },
];

const Clusters = () => {
  const classes = styles();
  return (
    <Box className={classes.clusters}>
      <Box className={classes.clustersContainer}>
        <Grid container rowSpacing={6} justifyContent="center">
          {clusters.map((cluster, index) => {
            let alignmentClass;
            switch (index % 3) {
              case 0:
                alignmentClass = classes.alignLeft;
                break;
              case 1:
                alignmentClass = classes.alignCenter;
                break;
              case 2:
                alignmentClass = classes.alignRight;
                break;
              default:
                alignmentClass = "";
            }
            return (
              <Grid
                xs={6}
                sm={4}
                item
                key={cluster.id}
                className={alignmentClass}
              >
                <Box className={classes.imageClusterContainer}>
                  <img
                    src={cluster.src}
                    alt={`Pratitioners ${cluster.id}`}
                    className={classes.imageCluster}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default Clusters;
