import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const styles = makeStyles((theme: SinesTheme) => ({
  elevate: {},
  textPlusContainer: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontFamily: "Montserrat Medium",
      fontSize: 18,
      marginLeft: 20,
    },
  },
}));

export default styles;
