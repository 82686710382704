export const decimalAdjust = (type: string, value: number, exp: number = 0) => {
  if (!["round", "floor", "ceil"].includes(type)) {
    throw new TypeError(
      "The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'."
    );
  }
  if (exp % 1 !== 0 || Number.isNaN(value)) {
    return NaN;
  } else if (exp === 0) {
    switch (type) {
      case "floor":
        return Math["floor"](value);
      case "round":
        return Math["round"](value);
      case "ceil":
        return Math["ceil"](value);
      default:
        break;
    }
  }
  const [magnitude, exponent = 0] = value.toString().split("e");
  let adjustedValue: number | undefined;

  const adjustedValueAux =
    magnitude.toString() + "e" + (+exponent - exp).toString();
  switch (type) {
    case "floor":
      adjustedValue = Math["floor"](Number(adjustedValueAux));
      break;
    case "round":
      adjustedValue = Math["round"](Number(adjustedValueAux));
      break;
    case "ceil":
      adjustedValue = Math["ceil"](Number(adjustedValueAux));
      break;
    default:
      break;
  }

  // Shift back
  if (adjustedValue) {
    const [newMagnitude, newExponent = 0] = adjustedValue.toString().split("e");
    return Number(`${newMagnitude}e${+newExponent + exp}`);
  }
};
