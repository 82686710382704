import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "./styles";

type Props = {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
};

const SectionWrapper: React.FC<Props> = ({ title, subtitle, children }) => {
  const classes = styles();
  return (
    <Box className={classes.sectionWrapper}>
      {(title || subtitle) && (
        <Box className={classes.textWrapper}>
          {title && <Typography className={classes.title}>{title}</Typography>}
          {subtitle && (
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          )}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default SectionWrapper;
