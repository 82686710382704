import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme: SinesTheme) => ({
  plusSign: {
    width: 22,
    height: 22,
  },
  plusSignIcon: {
    position: "relative",
    width: "22px",
    height: "22px",
    "&::before": {
      content: "''",
      position: "absolute",
      background: "#fff",
      top: 0,
      bottom: 0,
      left: 9,
      right: 9,
    },
    "&::after": {
      content: "''",
      position: "absolute",
      background: "#fff",
      top: 9,
      bottom: 9,
      left: 0,
      right: 0,
    },
  },
}));

export default styles;
