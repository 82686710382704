import TermsPrivacyCookies from "../../components/TermsPrivacyCookies";
import TextComponent from "../../components/TextComponent";
import termsData from "./termsText.json";

function Terms() {
  return (
    <TermsPrivacyCookies
      title="Service terms + conditions"
      subtitle="Terms of Service"
    >
      {termsData.content.map((element) => {
        return <TextComponent key={element.id} element={element} />;
      })}
    </TermsPrivacyCookies>
  );
}
export default Terms;
