import * as React from "react";

import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import SinesLogo from "../../assets/images/sines_logo.svg";
import "../../assets/styles/global.css";
import CustomButton from "../CustomButton";
import { navBarStyle } from "./styles";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { envConfig } from "../../config/config";

interface Page {
  label: string;
  path?: string;
  children?: Page[];
}

const pages: Page[] = [
  { label: "Science", path: "/science" },
  { label: "Individuals", path: "/individuals" },
  {
    label: "Businesses",
    children: [
      { label: "Locations", path: "/locations" },
      { label: "Teams", path: "/teams" },
      { label: "Practitioners", path: "/patients" },
    ],
  },
  { label: "Pricing", path: "/pricing" },
];

const pagesMobile = [
  { label: "Science", path: "/science" },
  { label: "Individuals", path: "/individuals" },
  { label: "Locations", path: "/locations" },
  { label: "Teams", path: "/teams" },
  { label: "Practitioners", path: "/patients" },
  { label: "Pricing", path: "/pricing" },
];

function ResponsiveAppBar() {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.down(800));
  const [anchorElNavMobile, setAnchorElNavMobile] =
    React.useState<null | HTMLElement>(null);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNavMobile(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNavMobile(null);
  };

  const handleOpenBusinessMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseBusinessMenu = () => {
    setAnchorElNav(null);
  };

  const classes = navBarStyle();
  const navigate = useNavigate();

  return (
    <>
      <AppBar position="relative" className={classes.appBar}>
        <Container maxWidth="lg" sx={{ zIndex: 10 }}>
          <Toolbar disableGutters className={classes.toolbar}>
            {lgUp && (
              <Box className={classes.menuMobile}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  classes={{ paper: classes.menuPaperMobile }}
                  id="menu-appbar"
                  anchorEl={anchorElNavMobile}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNavMobile)}
                  onClose={handleCloseNavMenu}
                >
                  {pagesMobile.map((page) => (
                    <MenuItem
                      key={page.label}
                      onClick={() => {
                        handleCloseNavMenu();
                        navigate(`${page.path}`);
                      }}
                    >
                      <Typography key={page.label} textAlign="center">
                        {page.label}
                      </Typography>
                    </MenuItem>
                  ))}
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link
                      className={classes.signIn}
                      href={`${envConfig.REACT_APP_FRONTEND_URL}/login`}
                    >
                      Sign In
                    </Link>
                  </MenuItem>
                </Menu>
              </Box>
            )}
            <Box className={classes.menu}>
              <Box className={classes.logoWrapper}>
                <img
                  onClick={() => {
                    navigate("/home");
                  }}
                  className={classes.logoImage}
                  src={SinesLogo}
                  alt="Sines"
                />
              </Box>
              {!lgUp && (
                <Box className={classes.buttonsWrapper}>
                  {pages.map((page) =>
                    page.children ? (
                      <Box key={page.label}>
                        <Button
                          className={[
                            classes.menuItem,
                            anchorElNav ? classes.hoverButton : "",
                          ].join(" ")}
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onMouseOver={handleOpenBusinessMenu}
                          color="inherit"
                        >
                          {page.label}
                        </Button>
                        <Menu
                          id="menu-appbar"
                          anchorEl={anchorElNav}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          open={Boolean(anchorElNav)}
                          onClose={handleCloseBusinessMenu}
                          MenuListProps={{
                            onMouseLeave: handleCloseBusinessMenu,
                          }}
                          classes={{ paper: classes.menuPaper }}
                        >
                          {page.children.map((child) => (
                            <MenuItem
                              key={child.label}
                              onClick={() => {
                                handleCloseBusinessMenu();
                                navigate(`${child.path}`);
                              }}
                            >
                              <Typography textAlign="center">
                                {child.label}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Menu>
                      </Box>
                    ) : (
                      <Button
                        key={page.label}
                        className={classes.menuItem}
                        onClick={() => {
                          navigate(`${page.path}`);
                        }}
                        onMouseOver={handleCloseBusinessMenu}
                      >
                        {page.label}
                      </Button>
                    )
                  )}
                  <CustomButton
                    label="Sign in"
                    url={`${envConfig.REACT_APP_FRONTEND_URL}/login`}
                    background="gray"
                  />
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default ResponsiveAppBar;
