import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import triangle from "../../assets/images/menu/menu_background.svg";

const navBarStyle = makeStyles((theme: SinesTheme) => ({
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    zIndex: 1301,
    paddingTop: 16,
    marginBottom: "-90px",
  },
  toolbar: {
    display: "block",
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuMobile: {
    flexGrow: 1,
  },
  logoImage: {
    width: "100px",
    height: "68px",
    cursor: "pointer",
  },
  menuItem: {
    color: "#FFFFFF",
    fontFamily: "Montserrat Medium",
    fontSize: "16px",
    lineHeight: "19px",
    marginRight: "30px",
    textTransform: "none",
    borderRadius: 26,
    padding: "10px 20px",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.1)",
    },
  },
  buttonsWrapper: {
    display: "flex",
    alignItems: "center",
  },
  signIn: {
    textDecoration: "none",
    color: "inherit",
  },
  hoverButton: {
    background: "rgba(255, 255, 255, 0.1)",
  },
  menuPaper: {
    background: "transparent",
    boxShadow: "none",
    marginTop: 10,
    overflow: "visible",
    "& ul": {
      backgroundImage: `url(${triangle})`,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      backgroundColor: "transparent",
      padding: "12px 20px 10px",
      borderRadius: 26,
      position: "relative",
      "&:before": {
        content: '""',
        color: "#fff",
        position: "absolute",
        top: 8,
        bottom: 2,
        left: 2,
        right: 2,
        boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.6)",
        borderRadius: 26,
        zIndex: -1,
      },
    },
    "& li": {
      fontFamily: "Montserrat Medium",
      fontSize: 16,
      padding: "15px 20px",
      color: "#fff",
      borderBottom: "1px solid #434343",
      display: "flex",
      justifyContent: "center",
      background: "#242424",
      "&:focus": {
        background: "#242424",
      },
      "&:last-child": {
        borderBottom: 0,
      },
      "&:hover": {
        backgroundImage:
          "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 50.72%, rgba(255, 255, 255, 0) 100%)",
      },
    },
  },
  menuPaperMobile: {
    background: "#242424",
    borderRadius: 26,
    padding: 0,
    border: "solid 1px transparent",
    boxShadow: "0px 1000px 0px #242424 inset",
    backgroundImage:
      "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
    backgroundOrigin: "border-box",
    "& ul": {
      backgroundColor: "transparent",
      padding: "0 20px",
      borderRadius: 26,
      overflow: "hidden",
    },
    "& li": {
      fontFamily: "Montserrat Medium",
      fontSize: 16,
      padding: "15px 10px",
      color: "#fff",
      borderBottom: "1px solid #434343",
      display: "flex",
      justifyContent: "center",
      background: "#2d2d2d",
      "&:focus": {
        background: "#242424",
      },
      "&:last-child": {
        borderBottom: 0,
      },
      "&:hover": {
        backgroundImage:
          "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 50.72%, rgba(255, 255, 255, 0) 100%)",
      },
    },
  },
  [theme.breakpoints.down("md")]: {
    menuItem: {
      fontSize: 14,
      marginRight: "10px",
    },
  },
  [theme.breakpoints.down(800)]: {
    appBar: {
      zIndex: 900,
    },
    toolbar: {
      display: "flex",
    },
    menuMobile: {
      display: "flex",
      position: "absolute",
      right: 0,
    },
  },
}));

export { navBarStyle };
