import { Container, Grid, Typography } from "@mui/material";
import JoinUsFooter from "../../components/JoinUsFooter";
import PageWrapper from "../../components/PageWrapper";
import ScienceCard from "./ScienceCard";
import scienceData from "./science.json";
import styles from "./styles";

function Science() {
  const classes = styles();
  const spacing = 4;
  return (
    <>
      <PageWrapper>
        <Container>
          <Typography className={classes.title}>
            The Science of SINES
          </Typography>
          <Typography className={classes.subtitle}>
            Our music is based on studies by leading neuroscientists and doctors
            in sound therapy, brainwave synchronization, and mood-based binaural
            music.
          </Typography>
          <Grid container spacing={spacing}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={spacing}>
                {scienceData.cards.map((card, index) => {
                  return (
                    index % 2 === 0 && (
                      <Grid item xs={12} key={card.id}>
                        <ScienceCard
                          info={card.info}
                          source={card.source}
                          sourceLink={card.sourceLink}
                        />
                      </Grid>
                    )
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={spacing}>
                {scienceData.cards.map((card, index) => {
                  return (
                    index % 2 !== 0 && (
                      <Grid item xs={12} key={card.id}>
                        <ScienceCard
                          info={card.info}
                          source={card.source}
                          sourceLink={card.sourceLink}
                        />
                      </Grid>
                    )
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </PageWrapper>
      <JoinUsFooter finalText="Have questions? Reach out to us. We're happy to help!" />
    </>
  );
}

export default Science;
