import { Box, Grid, Typography } from "@mui/material";
import IconAndroid from "../../../assets/images/locationsImg/channel_android.svg";
import IconApple from "../../../assets/images/locationsImg/channel_apple.svg";
import IconWeb from "../../../assets/images/locationsImg/channel_web.svg";
import IconWindows from "../../../assets/images/locationsImg/channel_winblows.svg";
import PlusSign from "../../../components/PlusSign";
import styles from "./styles";

type Props = {
  info: Array<string>;
};
const Systems: React.FC<Props> = ({ info }) => {
  const classes = styles();

  return (
    <Box className={classes.systems}>
      <Box className={classes.icons}>
        <img src={IconApple} alt="IconApple" className={classes.icon} />
        <img src={IconAndroid} alt="IconAndroid" className={classes.icon} />
        <img src={IconWeb} alt="IconWeb" className={classes.icon} />
        <img src={IconWindows} alt="IconWindows" className={classes.icon} />
      </Box>
      <Grid container spacing={2}>
        {info.map((str) => {
          return (
            <Grid item xs={12} sm={6} md={6} key={str}>
              <Box className={classes.textPlusContainer}>
                <PlusSign />
                <Typography>{str}</Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Systems;
