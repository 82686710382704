import { Container } from "@mui/material";
import PratitionersImg from "../../assets/images/practitioners/pp_header-image.png";
import tablet from "../../assets/images/practitioners/tablet.png";
import Faq from "../../components/Faq";
import InfoCards from "../../components/InfoCards";
import JoinUsFooter from "../../components/JoinUsFooter";
import PageHero from "../../components/PageHero";
import PageWrapperWithHeader from "../../components/PageWrapperWithHeader";
import SectionWrapper from "../../components/SectionWrapper";
import ImageSection from "../Individuals/ImageSection";
import BrainWaves from "./BrainWaves";
import Clusters from "./Clusters";
import Elevate from "./Elevate";
import info from "./info.json";

function Practitioners() {
  return (
    <>
      <PageWrapperWithHeader
        header={
          <Container>
            <PageHero
              title="Align your patient’s mind + body to optimal states for care"
              subtitle="Binaural music + guided meditations for all care experiences"
              image={PratitionersImg}
            />
          </Container>
        }
      >
        <Container>
          <SectionWrapper
            title="A practitioner's toolkit"
            subtitle="Intuitive. Turn-key. Easy to navigate."
          >
            <ImageSection img={tablet} />
          </SectionWrapper>
          <SectionWrapper title="Elevate your treatment initiatives + results">
            <Elevate info={info.elevate} />
          </SectionWrapper>
          <SectionWrapper
            title="Support for all care experiences"
            subtitle="SINES is for providers of all types and sizes from regional healthcare networks to individual practices."
          >
            <InfoCards cards={info.cards} />
          </SectionWrapper>
          <Clusters />
          <SectionWrapper>
            <BrainWaves />
          </SectionWrapper>
          <SectionWrapper title="Frequently asked questions">
            <Faq info="Practitioners" stepProp={5} />
          </SectionWrapper>
        </Container>
      </PageWrapperWithHeader>
      <JoinUsFooter finalText="If you need a program that's custom to your business, our team can help" />
    </>
  );
}

export default Practitioners;
