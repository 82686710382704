import { Container } from "@mui/material";
import BrainWaves from "../../components/BrainWaves";
import InfoCard from "../../components/InfoCards";
import JoinUsFooter from "../../components/JoinUsFooter";
import PageWrapperWithHeader from "../../components/PageWrapperWithHeader";
import SectionWrapper from "../../components/SectionWrapper";
import CardSection from "./CardsSection";
import IntroHomepage from "./IntroHomepage";
import MidSectionBoard from "./MiddleSectionBoard";
import info from "./info.json";

function Home() {
  return (
    <>
      <PageWrapperWithHeader header={<IntroHomepage />}>
        <MidSectionBoard />
        <CardSection />
        <Container>
          <SectionWrapper title="Our music + visuals are based on studies by leading neuroscientists and doctors">
            <InfoCard cards={info.cards} />
          </SectionWrapper>
          <SectionWrapper>
            <BrainWaves />
          </SectionWrapper>
        </Container>
      </PageWrapperWithHeader>
      <JoinUsFooter finalText="Have questions? Reach out to us. We're happy to help!" />
    </>
  );
}

export default Home;
