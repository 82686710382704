const currencies = [
  { id: "usd", name: "USD", symbol: "$" },
  { id: "cad", name: "CAD", symbol: "$" },
  { id: "aud", name: "AUD", symbol: "$" },
  { id: "gbp", name: "GBP", symbol: "£" },
  { id: "eur", name: "EUR", symbol: "€" },
];

const getCurrency: (id: string) =>
  | {
      id: string;
      name: string;
      symbol: string;
    }
  | undefined = (id: string) => {
  return currencies.find((currency) => currency.id === id);
};

export { currencies, getCurrency };
