import { Box, Typography } from "@mui/material";
import BrainHemespheres from "../../assets/images/brain_hemespheres.svg";

import ListenerGraph from "../../assets/images/listener_infograph.svg";

import CustomButton from "../CustomButton";
import { brainWavesStyle } from "./styles";

function BrainWaves() {
  const classes = brainWavesStyle();

  return (
    <Box className={classes.brainWavesContainer}>
      <Box className={classes.wrapper}>
        <Box className={classes.textWrapper}>
          <Typography className={classes.title}>
            Synchronizing brainwaves + hemispheres
          </Typography>
          <Box className={classes.buttonWrapper}>
            <CustomButton
              label="Learn More"
              background="none"
              isChevron
              url="/science"
              hasPadding={false}
            />
          </Box>
        </Box>
        <Box className={classes.imgWrapper}>
          <img
            src={BrainHemespheres}
            alt="brain"
            loading="lazy"
            className={classes.image}
          />
        </Box>
      </Box>
      <hr className={classes.divider} />
      <Box className={classes.wrapper}>
        <Box className={classes.imgWrapper}>
          <img
            src={ListenerGraph}
            alt="brain"
            loading="lazy"
            className={classes.image}
          />
        </Box>
        <Box className={classes.textWrapper}>
          <Typography className={classes.title}>
            A Wellness Ecosystem is Created + Sustained
          </Typography>
          <Box className={classes.buttonWrapper}>
            <CustomButton
              label="Learn More"
              background="none"
              isChevron
              url="/science"
              hasPadding={false}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default BrainWaves;
