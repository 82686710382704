import TermsPrivacyCookies from "../../components/TermsPrivacyCookies";
import TextComponent from "../../components/TextComponent";
import cookieData from "./cookiesText.json";

function Cookies() {
  return (
    <TermsPrivacyCookies title="Have a cookie" subtitle="Cookie settings">
      {cookieData.content.map((element) => {
        return <TextComponent key={element.label} element={element} />;
      })}
    </TermsPrivacyCookies>
  );
}
export default Cookies;
