import TermsPrivacyCookies from "../../components/TermsPrivacyCookies";
import TextComponent from "../../components/TextComponent";
import privacyData from "./privacyText.json";

function Privacy() {
  return (
    <TermsPrivacyCookies
      title="Your privacy matters to us"
      subtitle="Privacy Policy"
    >
      {privacyData.content.map((element) => {
        return <TextComponent key={element.id} element={element} />;
      })}
    </TermsPrivacyCookies>
  );
}
export default Privacy;
