import { Box, Button, Container } from "@mui/material";
import { useState } from "react";
import { midSectionStyle } from "./styles";

import Individuals from "../../../assets/images/homePageTabs/individuals.png";
import Locations from "../../../assets/images/homePageTabs/locations.png";
import Patients from "../../../assets/images/homePageTabs/patients.png";
import Practitioners from "../../../assets/images/homePageTabs/practitioners.png";
import Teams from "../../../assets/images/homePageTabs/teams.png";
import BoardContent from "./BoardContent";
import boardData from "./boardData.json";

function MidSectionBoard() {
  const classes = midSectionStyle();
  const [step, setStep] = useState(5);
  const [selectedPage, setSelectedPage] = useState("Individuals");

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        <Box className={classes.midBoard}>
          {/* <Box> */}
          <Box className={classes.btnGrid}>
            {boardData.buttons.map((button) => (
              <Box className={classes.buttonBoardGrid} key={button.name}>
                <Button
                  className={[
                    classes.buttonBoard,
                    selectedPage === button.name ? "active" : "",
                  ].join(" ")}
                  key={button.name}
                  onClick={() => {
                    setStep(button.step);
                    setSelectedPage(button.name);
                  }}
                >
                  {button.name}
                </Button>
              </Box>
            ))}
          </Box>
          <Box className={classes.boards}>
            {step === 1 && (
              <BoardContent
                image={Locations}
                url={selectedPage}
                content={boardData.location}
              />
            )}
            {step === 2 && (
              <BoardContent
                image={Teams}
                url={selectedPage}
                content={boardData.teams}
              />
            )}
            {step === 3 && (
              <BoardContent
                image={Practitioners}
                url={selectedPage}
                content={boardData.pratitioners}
              />
            )}
            {step === 4 && (
              <BoardContent
                image={Patients}
                url={selectedPage}
                content={boardData.patients}
              />
            )}
            {step === 5 && (
              <BoardContent
                image={Individuals}
                url={selectedPage}
                content={boardData.individuals}
              />
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default MidSectionBoard;
