declare global {
  interface Window {
    __RUNTIME_CONFIG__: EnvConfig;
  }
}

interface EnvConfig {
  REACT_APP_BACKEND_URL: string;
  REACT_APP_FRONTEND_URL: string;
  REACT_APP_GTM_ID: string;
  REACT_APP_GTM_PREVIEW: string;
  REACT_APP_GTM_AUTH: string;
}

const EMPTY_VALUE = "x";

const convertConfigValue = (value: string) => {
  if (value === EMPTY_VALUE) return "";
  return value;
};

const mapEmptyValues = (config: EnvConfig) => {
  const keys = Object.keys(config) as Array<keyof EnvConfig>;
  const result: Partial<EnvConfig> = {};
  keys.forEach((key) => {
    result[key] = convertConfigValue(config[key]);
  });
  return result as EnvConfig;
};

export const envConfig: EnvConfig = mapEmptyValues({
  ...process.env,
  ...window.__RUNTIME_CONFIG__,
});
