import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import CustomButton from "../CustomButton";
import styles from "./styles";

type Props = {
  title: string;
  subtitle: string;
  image: string;
};

const PageHero: React.FC<Props> = ({ title, subtitle, image }) => {
  const classes = styles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box className={classes.pageHero}>
      <Grid container>
        <Grid item sm={6} md={5}>
          <Box className={classes.textContainer}>
            <Box>
              <Typography className={classes.title}>{title}</Typography>
              <Typography className={classes.subtitle}>{subtitle}</Typography>
            </Box>
            <Box className={classes.action}>
              <CustomButton label="Sign up" url="/pricing" />
              <Typography className={classes.footer}>
                Start your 7-day free trial. No payment details required.
              </Typography>
            </Box>
          </Box>
        </Grid>
        {smUp && (
          <Grid item xs={3} sm={6} md={7}>
            <Box className={classes.heroImageContainer}>
              <img className={classes.heroImage} src={image} alt="Intro" />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PageHero;
