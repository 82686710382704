import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const faqStyles = makeStyles((theme: SinesTheme) => ({
  accordionGrid: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  faqAccordion: {
    backgroundColor: "#2D2D2D",
    border: "1px solid #7A5EA7",
    borderRadius: "20px !important",
    width: "80%",
    marginTop: "10px !important",
    marginBottom: "10px !important",
  },
  faqText: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
    paddingRight: "38px",
    paddingLeft: "38px",
  },
  faqQuestion: {
    color: "#FFFFFF",
    fontFamily: "Montserrat Semibold",
    lineHeight: "21px",
    textAlign: "center",
  },
  faqAcordeonTitle: {
    color: "#FFFFFF",
    fontFamily: "Montserrat Semibold",
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  faqNavbarWrapper: {
    display: "flex",
    background: "linear-gradient(to right, #775DA8, #F06B5D)",
    borderRadius: "22px",
    width: "702px",
    height: "46px",
    justifyContent: "center",
    alignItems: "center",
  },
  faqNavbar: {
    borderRadius: "22px",
    height: "44px",
    display: "flex",
  },
  smallScreenAccordeon: {
    display: "none",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  faqButton: {
    borderRight: "1px solid #9F3D71",
    borderRadius: "0",
    width: "140px",
    height: "auto",
    color: "#F4F4F4",
    background: "black",
    fontFamily: "Montserrat Semibold",
    textTransform: "capitalize",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    "&:last-child": {
      borderTopRightRadius: "22px",
      borderBottomRightRadius: "22px",
    },
    "&:first-child": {
      borderTopLeftRadius: "22px",
      borderBottomLeftRadius: "22px",
    },
  },
  selectedButton: {
    borderRight: "1px solid #9F3D71",
    backgroundColor: "#2D2D2D",
    borderRadius: "0",
    width: "140px",
    height: "auto",
    color: "#F4F4F4",
    fontFamily: "Montserrat Semibold",
    textTransform: "capitalize",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    "&:last-child": {
      borderRight: "none",
      borderTopRightRadius: "22px",
      borderBottomRightRadius: "22px",
    },
    "&:first-child": {
      borderTopLeftRadius: "22px",
      borderBottomLeftRadius: "22px",
    },
  },
  questionsBox: {
    marginTop: "40px",
  },
  finalTextBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "80px",
  },
  finalText: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "36px",
    lineHeight: "43px",
    width: "920px",
    textAlign: "center",
  },
  finalSubText: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "24px",
    lineHeight: "43px",
    width: "920px",
    textAlign: "center",
    marginTop: "30px",
  },
  faqQuestionWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    faqAccordion: { width: "100%" },
    faqNavbar: { display: "none" },
    faqNavbarWrapper: { display: "none" },
    questionsBox: { display: "none" },
    finalText: { fontSize: "20px" },
    finalSubText: { fontSize: "12px" },
    smallScreenAccordeon: { display: "flex" },
  },
}));

export { faqStyles };
