import { Box, Container } from "@mui/material";
import IndividualsImg from "../../assets/images/individualsImg/header_image.png";
import tablet from "../../assets/images/individualsImg/tablet.png";
import BrainWaves from "../../components/BrainWaves";
import CustomButton from "../../components/CustomButton";
import Faq from "../../components/Faq";
import InfoCards from "../../components/InfoCards";
import JoinUsFooter from "../../components/JoinUsFooter";
import PageHero from "../../components/PageHero";
import PageWrapperWithHeader from "../../components/PageWrapperWithHeader";
import SectionWrapper from "../../components/SectionWrapper";
import ImageSection from "./ImageSection";
import Optimize from "./Optimize";
import informationSectionData from "./info.json";
import IndividualsFooter from "../../components/IndividualsFooter";

function Individuals() {
  return (
    <>
      <PageWrapperWithHeader
        header={
          <Container>
            <PageHero
              title="We empower people to achieve wellbeing"
              subtitle="Discover the power of binaural music + guided meditations to help elevate you through all life experiences"
              image={IndividualsImg}
            />
          </Container>
        }
      >
        <Container>
          <SectionWrapper title="Optimize your entire day">
            <Optimize />
          </SectionWrapper>
          <SectionWrapper title="Guided meditations for every experience">
            <ImageSection img={tablet} />
          </SectionWrapper>

          <SectionWrapper title="Elevate. Harmonize. Optimize.">
            <InfoCards cards={informationSectionData.intuitiveSection} />
          </SectionWrapper>
          <SectionWrapper title="Results Oriented">
            <InfoCards cards={informationSectionData.cards}>
              <Box sx={{ textAlign: "center", marginTop: 8 }}>
                <CustomButton
                  label="Learn More"
                  background="none"
                  isChevron
                  url="/pricing"
                />
              </Box>
            </InfoCards>
          </SectionWrapper>
          <SectionWrapper>
            <BrainWaves />
          </SectionWrapper>
          <SectionWrapper title="Frequently asked questions">
            <Faq info="Individuals" stepProp={2} />
          </SectionWrapper>
        </Container>
      </PageWrapperWithHeader>
      <IndividualsFooter />
    </>
  );
}

export default Individuals;
