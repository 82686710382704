import { Box, Container, Typography } from "@mui/material";
import CustomButton from "../CustomButton";
import styles from "./styles";

type Props = {
  finalText: string;
};

const JoinUsFooter: React.FC<Props> = ({ finalText }) => {
  const classes = styles();
  return (
    <Box sx={{ padding: "80px 0" }} className={classes.wrapperBg}>
      <Container maxWidth="lg">
        <Typography className={classes.title}>
          Join us! Start your 7-day free trial.
        </Typography>
        <Box
          sx={{
            marginTop: 10,
            marginBottom: 1,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <CustomButton label="Sign up" url="/pricing" />
        </Box>
        <Typography className={classes.smallText}>
          Join us! Start your 7-day free trial.
        </Typography>
        <Typography className={classes.mediumText}>{finalText}</Typography>
      </Container>
    </Box>
  );
};
export default JoinUsFooter;
