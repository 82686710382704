import { Box, Container, Grid } from "@mui/material";
import InfoCard from "../InfoCard";
import styles from "./styles";
type Cards = {
  title: string;
  description?: string;
};
type Props = {
  cards: Cards[];
  spacing?: string;
  justifyContent?: string;
  children?: React.ReactNode;
};

const InfoCards: React.FC<Props> = ({
  cards,
  spacing = 4,
  justifyContent = "center",
  children,
}) => {
  const classes = styles();

  return (
    <Box className={classes.infoContainer}>
      <Container maxWidth="lg">
        <Grid container spacing={spacing} justifyContent={justifyContent}>
          {cards.map((card) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={3} key={card.title}>
                <InfoCard title={card.title} description={card.description} />
              </Grid>
            );
          })}
        </Grid>
        {children}
      </Container>
    </Box>
  );
};

export default InfoCards;
