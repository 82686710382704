import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { textStyled } from "../../../utils/textStyled";
import styles from "./styles";

interface IScienceCard {
  source: string;
  info: string;
  sourceLink: string;
}

const ScienceCard: React.FC<IScienceCard> = ({ info, source, sourceLink }) => {
  const classes = styles();

  return (
    <Box className={classes.cardWrapper}>
      <Typography className={classes.infoText}>{textStyled(info)}</Typography>
      <Box>
        <Typography className={classes.sourceTitle}>SOURCE</Typography>
        <Link href={sourceLink} target="_blank" className={classes.sourceText}>
          {source}
        </Link>
      </Box>
    </Box>
  );
};
export default ScienceCard;
