import { SinesTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import footerBG from "../../assets/images/background_gradients/footer_trimmed.png";
import topBG from "../../assets/images/background_gradients/midrange_background.png";

const styles = makeStyles((theme: SinesTheme) => ({
  pricingWrapper: {
    position: "relative",
  },
  pricing: {
    paddingTop: 120,
    position: "relative",
    zIndex: 10,
  },
  topBackgroundWrapper: {
    position: "absolute",
    top: 0,
    height: "100vh",
    maxHeight: "1000px",
    left: 0,
    right: 0,
  },
  topBackground: {
    background: `url(${topBG})`,
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%",
    zIndex: 0,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
  },
  title: {
    color: "#FFF",
    fontSize: "66px",
    textAlign: "center",
    fontFamily: "Montserrat Semibold",
  },
  subHeader: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  togglePlans: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
    "& p": {
      fontSize: 18,
      fontFamily: "Montserrat Semibold",
    },
  },
  subtitle: {
    marginTop: 15,
    marginBottom: 30,
  },
  switchWrapper: {
    width: "68px",
    height: "30px",
    borderRadius: "34px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    margin: "0 10px 0 10px",
    background:
      "linear-gradient(135deg, #7A5EA7 0%, #A1628E 51.2%, rgba(201, 102, 117, 0.5) 100%)",
  },
  switch: {
    position: "relative",
    display: "inline-block",
    width: "66px",
    height: "28px",
    alignSelf: "center",
    "& input": {
      opacity: "0",
      width: "0",
      height: "0",
    },
  },
  slider: {
    position: "absolute",
    cursor: "pointer",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "#2D2D2D",
    borderRadius: "34px",
    webkitTransition: ".4s",
    transition: ".4s",

    "&:before": {
      position: "absolute",
      content: '""',
      height: "22px",
      width: "22px",
      left: "4px",
      bottom: "3px",
      borderRadius: "50%",
      backgroundImage: "linear-gradient(68.02deg, #775DA8 0%, #F06B5D 100%)",
      webkitTransition: ".4s",
      transition: ".4s",
    },
    "input:checked + &:before ": {
      webkitTransform: "translateX(38px)",
      msTransform: "translateX(38px)",
      transform: "translateX(38px)",
    },
  },
  footerPricing: {
    background: `url(${footerBG})`,
    backgroundSize: "100% 200%",
    backgroundRepeat: "no-repeat",
    marginTop: 80,
    paddingBottom: 90,
    "& p": {
      color: "#FFFFFF",
      fontSize: 24,
      marginTop: 30,
      textAlign: "center",
      fontFamily: "Montserrat",
    },
  },
  [theme.breakpoints.down("md")]: {
    title: {
      fontSize: "45px",
    },
    togglePlans: {
      textAlign: "center",
      alignItems: "center",
    },
    subtitle: {
      textAlign: "center",
    },
  },
}));

export default styles;
