import { Box } from "@mui/material";
import styles from "./styles";

type Props = {
  img: string;
};

const ImageSection: React.FC<Props> = ({ img }) => {
  const classes = styles();
  return (
    <Box className={classes.imgContainer}>
      <img src={img} alt="Sines App" className={classes.image} loading="lazy" />
    </Box>
  );
};
export default ImageSection;
